import React, { useState } from 'react';

import { Link } from 'react-router-dom'

export default function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    console.log('Menu toggled:', isMenuOpen);  
  };


  return (
   <>
      
	<header className="header">
  <div
    data-elementor-type="wp-post"
    data-elementor-id={181}
    className="elementor elementor-181"
  >
    <div
      className="elementor-element elementor-element-e69dd32 e-con-full e-flex e-con e-parent"
      data-id="e69dd32"
      data-element_type="container"
    >
      <div
        className="elementor-element elementor-element-0045e86 elementor-widget elementor-widget-restarheader"
        data-id="0045e86"
        data-element_type="widget"
        data-widget_type="restarheader.default"
      >
        <div className="elementor-widget-container">
          <div className="th-menu-wrapper">
            <div className="th-menu-area text-center">
              <button className="th-menu-toggle"
              onClick={toggleMenu}>
                <i className="fal fa-times" />
              </button>
              <div className="mobile-logo">
                <a href="index.html">
                  <img src="assets/images/logo1.png" alt="logo" />
                </a>
              </div>


              <div className="th-mobile-menu">
                      <ul id="menu-primary-menu" className="">
                        <li
                          id="menu-item-75"
                          className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home current-menu-ancestor current-menu-parent menu-item-75"
                        >
                          <Link to="/" onClick={toggleMenu}>Home</Link>
                        </li>
                        <li
                          id="menu-item-95"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-95"
                        >
                          <Link to="/aboutus" onClick={toggleMenu}>About Us</Link>
                        </li>
                        <li
                          id="menu-item-81"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-81"
                        >
                          <Link to="/kitchens" onClick={toggleMenu}>Kitchens</Link>
                        </li>
                      </ul>
              </div>
              
            </div>
          </div>
          <div className="sidemenu-wrapper sidemenu-cart">
            <div className="sidemenu-content">
              <button className="closeButton sideMenuCls">
                <i className="far fa-times" />
              </button>
              <div className="widget woocommerce widget_shopping_cart">
                <h3 className="widget_title">Shopping cart</h3>
                <div className="widget_shopping_cart_content">
                  <ul className="woocommerce-mini-cart cart_list product_list_widget  list-unstyled"></ul>
                  <p className="woocommerce-mini-cart__total total">
                    <strong>Subtotal</strong>
                    <span className="woocommerce-Price-amount amount">
                      <span className="woocommerce-Price-amount amount">
                        <span className="woocommerce-Price-currencySymbol">
                          ₹
                        </span>
                        0
                      </span>
                    </span>
                  </p>
                  <p className="woocommerce-mini-cart__buttons buttons">
                    <a
                      href="cart/index.html"
                      className="button checkout wc-forward th-btn style1"
                    >
                      View cart
                    </a>
                    <a
                      href="checkout/index.html"
                      className="button wc-forward th-btn style1"
                    >
                      Checkout
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="sidemenu-wrapper sidemenu-info">
            <div className="sidemenu-content">
              <button className="closeButton sideMenuCls">
                <i className="far fa-times" />
              </button>
              <div id="restar_aboutus_widget-2" className="widget no-class">
                <div className="widget footer-widget">
                  <div className="th-widget-about">
                    <div className="about-logo">
                      <a href="index.html">
                        <img src="assets/images/logo.png" alt="logo" />
                      </a>
                    </div>
                    <p className="about-text">
                      We provide specialized winterization services to safeguard
                      your pool during the off-season, and when spring arrives,
                      we handle the thorough opening process.
                    </p>
                    <div className="th-social  footer-social">
                      <a href="https://facebook.com/">
                        <i className="fab fa-facebook-f" />
                      </a>
                      <a href="https://twitter.com/">
                        <i className="fab fa-twitter" />
                      </a>
                      <a href="https://instagram.com/">
                        <i className="fab fa-instagram" />
                      </a>
                      <a href="https://linkedin.com/">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div id="block-9" className="widget widget_block">
                <div className="widget  ">
                  <h3 className="widget_title">Get In Touch</h3>
                  <div className="th-widget-contact">
                    <div className="info-box">
                      <h4 className="box-title">Address Location</h4>
                      <div className="box-content">
                        <div className="box-icon">
                          <i className="fas fa-location-dot" />
                        </div>
                        <p className="box-text">138 MacArthur Ave, USA</p>
                      </div>
                    </div>
                    <div className="info-box">
                      <h4 className="box-title">Phone Number</h4>
                      <div className="box-content">
                        <div className="box-icon">
                          <i className="fas fa-phone" />
                        </div>
                        <p className="box-text">
                          <a href="tel:+16326543564">+(163)-2654-3564</a>
                        </p>
                      </div>
                    </div>
                    <div className="info-box">
                      <h4 className="box-title">Email address</h4>
                      <div className="box-content">
                        <div className="box-icon">
                          <i className="fas fa-envelope" />
                        </div>
                        <p className="box-text">
                          <a href="mailto:info@restar.com">info@restar.com</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="popup-search-box d-none d-lg-block">
            <button className="searchClose">
              <i className="fal fa-times" />
            </button>
            <form
              role="search"
              method="get"
              action="https://wordpress.themeholy.com/restar/"
            >
              <input
                defaultValue=""
                name="s"
                required=""
                type="search"
                placeholder="What are you looking for?"
              />
              <button type="submit">
                <i className="fal fa-search" />
              </button>
            </form>
          </div>
          <div className="th-header header-layout3">
            <div className="sticky-wrapper">
              
			<div className="menu-area">
									<div className="container-fluid px-0">
										<div className="row align-items-center justify-content-between">
											<div className="col-xl-2 col-lg-2 col-3 px-0">
												<div className="header-logo"><a href="index.html"><img
															src="assets/images/logo1.png"
															alt="logo white" /></a></div>
											</div>
											<div className="col-xl-6 col-lg-8 text-center">
												<nav className="main-menu d-none d-lg-inline-block ">
													<ul id="menu-primary-menu-1" className="">
														<li
															className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home current-menu-ancestor current-menu-parent menu-item-75">
															
                                                             <Link to="/" >Home</Link> 
														</li>
														<li
															className="menu-item menu-item-type-custom menu-item-object-custom menu-item-95">
															
                                                            <Link to="/aboutus">About Us</Link>
														</li>
														<li
															className="menu-item menu-item-type-custom menu-item-object-custom menu-item-96">
														
															<Link to="/kitchens">Kitchens</Link>
														</li>
														<li
															className="menu-item menu-item-type-custom menu-item-object-custom menu-item-96">
													
															<Link to="/cuisines">Cuisines</Link>
														</li>
														<li
															className="menu-item menu-item-type-post_type menu-item-object-page menu-item-81">
														
															<Link to="/contactus">Contact</Link>
														</li>
													</ul>
												</nav>
											</div>
											<div className="col-xl-4 col-lg-2 col-9 px-0 d-flex justify-content-end">
												<div className="header-button">
													<button type="button" className="icon-btn sideMenuToggler"><span
															className="badge cart_badge">0</span><i
															className="far fa-cart-shopping"></i></button>
													<div className="header-call">
														<h3 className="box-title"><a href="tel:+9878218015"><span
																	className="icon"></span> +987-82-18015</a></h3>
														<p className="box-text">Contact us for reservation</p>
													</div>
                          <button type="button" className="square-btn sideMenuInfo"><i
															className="fal fa-bars"
                              onClick={toggleMenu}>

                              </i>
                              </button>
                              <button
                    type="button"
                    className="th-menu-toggle d-block d-lg-none"
                    onClick={toggleMenu}
                  >
                    <i className="far fa-bars"></i>
                  </button>
												</div>
											</div>
										</div>
									</div>
								</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

   </>
  )
}
