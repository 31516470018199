import React, { useEffect } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { Zoom, Fade, Slide, Rotate } from "react-awesome-reveal";
import { Link } from 'react-router-dom'


export default function Home() {

    const settings = {

        infinite: true,
        autoplay: true,
        speed: 800,

        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: true,

                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: true,

                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: true,

                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: true,

                }
            }
        ]
    }

    const settings2 = {

        infinite: true,
        autoplay: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,

                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,

                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,

                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,

                }
            }
        ]
    }


    const settings3 = {

        infinite: true,
        autoplay: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,

                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,

                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,

                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,

                }
            }
        ]
    }

    const settings4 = {

        infinite: true,
        autoplay: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,

                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,

                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,

                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,

                }
            }
        ]
    }




    return (
        <>
            <div>
                <div id="smooth-wrapper">
                    <div id="smooth-content">
                        <div className="restar-fluid">
                            <div className="builder-page-wrapper">
                                <div data-elementor-type="wp-page" data-elementor-id="51" className="elementor elementor-51">
                                    <div className="elementor-element elementor-element-33f37fa e-con-full e-flex e-con e-parent"
                                        data-id="33f37fa" data-element_type="container">
                                        <div className="elementor-element elementor-element-b52d45a elementor-widget elementor-widget-restarbanner1"
                                            data-id="b52d45a" data-element_type="widget" data-widget_type="restarbanner1.default">
                                            <div className="elementor-widget-container">
                                                <div className="th-hero-wrapper hero-3" id="hero">
                                                    <div className="banner-area">
                                                        <div className="ball-shape">
                                                            <div className="img1"><img decoding="async"
                                                                src="assets/images/ball_1.svg" alt="ball_1" /></div>
                                                            <div className="img2"><img decoding="async"
                                                                src="assets/images/ball_2.svg" alt="ball_2" /></div>
                                                            <div className="img3"><img decoding="async"
                                                                src="assets/images/ball_3.svg" alt="ball_3" /></div>
                                                        </div>


                                                        <div className="swiper th-slider" id="heroSlide3"
                                                            data-bg-src="assets/images/hero_bg_3_1.png"
                                                            data-slider-options='{"effect":"fade","autoHeight":true}'>


                                                            <Slider {...settings}>





                                                                <div className="swiper-slide">
                                                                    <div className="hero-inner">
                                                                        <div className="hero-overlay"
                                                                        ></div>
                                                                        <div className="container th-container">

                                                                            <div className="hero-style3">
                                                                                <Fade direction='up'>
                                                                                    <span
                                                                                        className="hero-subtitle text-center sub"
                                                                                    >Healthy &amp;
                                                                                        Quality</span>
                                                                                    <h1 className="hero-title4 el-title"
                                                                                    >
                                                                                        Qualified</h1>
                                                                                    <h2 className="hero-title3 el-title2"
                                                                                    >Food
                                                                                        Chart</h2><a href="shop/index.html"
                                                                                            className="th-btn th_btn"
                                                                                        >Order
                                                                                        Now make pages </a>
                                                                                </Fade>
                                                                            </div>

                                                                        </div>
                                                                        <div className="hero-img">
                                                                            <div className="shape1"
                                                                            ><Slide direction='right'> <img decoding="async"
                                                                                src="assets/images/hero_shape_3_3.png"
                                                                                alt="hero_shape_3_3" /></Slide></div>
                                                                            <div className="offer"
                                                                            ><Slide direction='up'> <img decoding="async"
                                                                                src="assets/images/hero_offer_1.svg"
                                                                                alt="hero_offer_1" /></Slide></div>
                                                                            <Fade direction='right'>          <div className="img1"
                                                                            ><img decoding="async"
                                                                                src="assets/images/product/hero_3_2.png"
                                                                                alt="hero_3_2" /></div></Fade>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="swiper-slide">
                                                                    <div className="hero-inner">
                                                                        <div className="hero-overlay" data-ani="slideinright"
                                                                            data-ani-delay="0.7s"></div>
                                                                        <div className="container th-container">
                                                                            <div className="hero-style3">
                                                                                <Fade direction='up'>
                                                                                    <span
                                                                                        className="hero-subtitle text-center sub"
                                                                                        data-ani="slideinup"
                                                                                        data-ani-delay="0.2s">Healthy &amp;
                                                                                        Quality</span>
                                                                                    <h1 className="hero-title4 el-title"
                                                                                        data-ani="slideinup" data-ani-delay="0.4s">Fast
                                                                                        Food</h1>
                                                                                    <h2 className="hero-title3 el-title2"
                                                                                        data-ani="slideinup" data-ani-delay="0.5s">Food
                                                                                        Menu</h2><a href="shop/index.html"
                                                                                            className="th-btn th_btn" data-ani="slideinup"
                                                                                            data-ani-delay="0.7s">Order Now</a>
                                                                                </Fade>
                                                                            </div>
                                                                        </div>
                                                                        <div className="hero-img">
                                                                            <div className="shape1" data-ani="slideinup"
                                                                                data-ani-delay="0.5s"><Slide direction='right'> <img decoding="async"
                                                                                    src="assets/images/hero_shape_3_3.png"
                                                                                    alt="hero_shape_3_3" /></Slide></div>
                                                                            <div className="offer" data-ani="slideinup"
                                                                                data-ani-delay="0.4s"><Slide direction='up'> <img decoding="async"
                                                                                    src="assets/images/hero_offer_1.svg"
                                                                                    alt="hero_offer_1" /></Slide></div>
                                                                            <Fade direction='right'>      <div className="img1" data-ani="slideinright"
                                                                                data-ani-delay="0.1s"><img decoding="async"
                                                                                    src="assets/images/product/hero_3_3.png"
                                                                                    alt="hero_3_3" /></div></Fade>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="swiper-slide">
                                                                    <div className="hero-inner">
                                                                        <div className="hero-overlay" data-ani="slideinright"
                                                                            data-ani-delay="0.7s"></div>
                                                                        <div className="container th-container">

                                                                            <div className="hero-style3">
                                                                                <Fade direction='up'>
                                                                                    <span
                                                                                        className="hero-subtitle text-center sub  "
                                                                                        data-ani="slideinup"
                                                                                        data-ani-delay="0.2s">Healthy &amp;
                                                                                        Quality</span>
                                                                                    <h1 className="hero-title4 el-title"
                                                                                        data-ani="slideinup" data-ani-delay="0.4s">
                                                                                        Perfection</h1>
                                                                                    <h2 className="hero-title3 el-title2"
                                                                                        data-ani="slideinup" data-ani-delay="0.5s">Food
                                                                                        Menu</h2><a href="shop/index.html"
                                                                                            className="th-btn th_btn" data-ani="slideinup"
                                                                                            data-ani-delay="0.7s">Order Now</a>
                                                                                </Fade>
                                                                            </div>

                                                                        </div>
                                                                        <div className="hero-img">
                                                                            <div className="shape1"
                                                                                data-ani-delay="0.5s"><Slide direction='right'> <img decoding="async"
                                                                                    src="assets/images/hero_shape_3_3.png"
                                                                                    alt="hero_shape_3_3" /></Slide></div>
                                                                            <div className="offer" data-ani="slideinup"
                                                                                data-ani-delay="0.4s"> < Slide direction='up'> <img decoding="async"
                                                                                    src="assets/images/hero_offer_1.svg"
                                                                                    alt="hero_offer_1" /></Slide> </div>
                                                                            <Slide direction='right'>         <div className="img1" data-ani="slideinright"
                                                                                data-ani-delay="0.1s"><img decoding="async"
                                                                                    src="assets/images/product/hero_3_1.png"
                                                                                    alt="hero_3_1" /></div></Slide>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </Slider>



                                                        </div>
                                                        <div className="hero-bottom">
                                                            <div className="icon-box"><button data-slider-prev="#heroSlide3"
                                                                className="slider-arrow slider-prev default"><span
                                                                    className="icon"></span></button><button
                                                                        data-slider-next="#heroSlide3"
                                                                        className="slider-arrow slider-next default"><span
                                                                            className="icon"></span></button></div>
                                                            <div className="hero-thumb" data-slider-tab="#heroSlide3">
                                                                <div className="tab-btn active"><img decoding="async"
                                                                    src="assets/images/product/hero_3_1-85x85.png"
                                                                    alt="Image" /></div>
                                                                <div className="tab-btn"><img decoding="async"
                                                                    src="assets/images/product/hero_3_2-85x85.png"
                                                                    alt="Image" /></div>
                                                                <div className="tab-btn"><img decoding="async"
                                                                    src="assets/images/product/hero_3_3-85x85.png"
                                                                    alt="Image" /></div>
                                                            </div>
                                                        </div>
                                                    </div><a href="reservation/index.html" className="hero-btn">Book A Table</a>
                                                    <div className="hero-social">
                                                        <div className="social-links"><a rel="nofollow"
                                                            href="https://www.facebook.com/"><i aria-hidden="true"
                                                                className="fab fa-facebook-f"></i></a> <a rel="nofollow"
                                                                    href="https://www.twitter.com/"><i aria-hidden="true"
                                                                        className="fab fa-twitter"></i></a> <a rel="nofollow"
                                                                            href="https://www.linkedin.com/"><i aria-hidden="true"
                                                                                className="fab fa-linkedin-in"></i></a> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="elementor-element elementor-element-36cf04a e-flex e-con-boxed e-con e-parent"
                                        data-id="36cf04a" data-element_type="container bg-#fcf8e3">
                                        <div className="e-con-inner">
                                            <div className="elementor-element elementor-element-e194a3a e-con-full e-flex e-con e-child"
                                                data-id="e194a3a" data-element_type="container">
                                                <div className="elementor-element elementor-element-984d11a elementor-widget elementor-widget-restarcategory"
                                                    data-id="984d11a" data-element_type="widget"
                                                    data-widget_type="restarcategory.default">
                                                    <div className="elementor-widget-container">



                                                        <div className="slider-area">
                                                            <div className="swiper th-slider has-shadow" id="catSlider2"
                                                                data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"400":{"slidesPerView":2},"768":{"slidesPerView":3},"992":{"slidesPerView":4},"1200":{"slidesPerView":5},"1300":{"slidesPerView":6}}}'>
                                                                <Slider {...settings2}>
                                                                    <div className="swiper-slide">
                                                                        <div className="category-box">
                                                                            <div className="box-img"><img decoding="async"
                                                                                src="assets/images/product/category_box_1.png"
                                                                                alt="category_box_1" /></div>
                                                                            <h3 className="box-title"><a href="shop/index.html">Fried
                                                                                rice</a></h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="category-box">
                                                                            <div className="box-img"><img decoding="async"
                                                                                src="assets/images/product/category_box_2.png"
                                                                                alt="category_box_2" /></div>
                                                                            <h3 className="box-title"><a href="shop/index.html">Chicken
                                                                                Soup</a></h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="category-box">
                                                                            <div className="box-img"><img decoding="async"
                                                                                src="assets/images/product/category_box_3.png"
                                                                                alt="category_box_3" /></div>
                                                                            <h3 className="box-title"><a href="shop/index.html">Beef
                                                                                Noodles</a></h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="category-box">
                                                                            <div className="box-img"><img decoding="async"
                                                                                src="assets/images/product/category_box_4.png"
                                                                                alt="category_box_4" /></div>
                                                                            <h3 className="box-title"><a
                                                                                href="shop/index.html">Biryani</a></h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="category-box">
                                                                            <div className="box-img"><img decoding="async"
                                                                                src="assets/images/product/category_box_5.png"
                                                                                alt="category_box_5" /></div>
                                                                            <h3 className="box-title"><a href="shop/index.html">Chicken
                                                                                Pasta</a></h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="category-box">
                                                                            <div className="box-img"><img decoding="async"
                                                                                src="assets/images/product/category_box_6.png"
                                                                                alt="category_box_6" /></div>
                                                                            <h3 className="box-title"><a href="shop/index.html">Shrimp
                                                                                Soup</a></h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="category-box">
                                                                            <div className="box-img"><img decoding="async"
                                                                                src="assets/images/product/category_box_1.png"
                                                                                alt="category_box_1" /></div>
                                                                            <h3 className="box-title"><a href="shop/index.html">Fried
                                                                                rice</a></h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="category-box">
                                                                            <div className="box-img"><img decoding="async"
                                                                                src="assets/images/product/category_box_2.png"
                                                                                alt="category_box_2" /></div>
                                                                            <h3 className="box-title"><a href="shop/index.html">Chicken
                                                                                Soup</a></h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="category-box">
                                                                            <div className="box-img"><img decoding="async"
                                                                                src="assets/images/product/category_box_3.png"
                                                                                alt="category_box_3" /></div>
                                                                            <h3 className="box-title"><a href="shop/index.html">Beef
                                                                                Noodles</a></h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="category-box">
                                                                            <div className="box-img"><img decoding="async"
                                                                                src="assets/images/product/category_box_4.png"
                                                                                alt="category_box_4" /></div>
                                                                            <h3 className="box-title"><a href="shop/index.html">Beef
                                                                                Noodles</a></h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="category-box">
                                                                            <div className="box-img"><img decoding="async"
                                                                                src="assets/images/product/category_box_5.png"
                                                                                alt="category_box_5" /></div>
                                                                            <h3 className="box-title"><a href="shop/index.html">Chicken
                                                                                Pasta</a></h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="category-box">
                                                                            <div className="box-img"><img decoding="async"
                                                                                src="assets/images/product/category_box_6.png"
                                                                                alt="category_box_6" /></div>
                                                                            <h3 className="box-title"><a href="shop/index.html">Shrimp
                                                                                Soup</a></h3>
                                                                        </div>
                                                                    </div>

                                                                </Slider>



                                                            </div>


                                                        </div>
                                                        <button data-slider-prev="#catSlider2"
                                                            className="slider-arrow slider-prev"><span className="icon"></span></button>
                                                        <button data-slider-next="#catSlider2"
                                                            className="slider-arrow slider-next"><span className="icon"></span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="elementor-element elementor-element-92a9d50 e-flex e-con-boxed e-con e-parent main1"
                                        data-id="92a9d50" data-element_type="container">
                                        <div className="e-con-inner">
                                            <div className="elementor-element elementor-element-5e65fd3 e-con-full e-flex e-con e-child"
                                                data-id="5e65fd3" data-element_type="container">
                                                <div className="elementor-element elementor-element-cbc1b1e elementor-widget__width-initial elementor-widget-tablet__width-inherit elementor-widget elementor-widget-restaroffer"
                                                    data-id="cbc1b1e" data-element_type="widget"
                                                    data-widget_type="restaroffer.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="cta-card ">
                                                            <div className="box-price">
                                                                <div className="bg"><img decoding="async"
                                                                    src="assets/images/shape_bg_1.svg"
                                                                    alt="shape_bg_1" /></div>
                                                                <div className="content">
                                                                    <p className="text">Only</p>
                                                                    <h6 className="price">&#8377;5</h6>
                                                                </div>
                                                            </div>
                                                            <Zoom>
                                                                <h3 className="box-title title-ani2">Delicious</h3>
                                                                <h4 className="box-heading title-ani2">HUM BURGER</h4>
                                                                <h4 className="box-text title-ani2">Limited Offer</h4>
                                                                <div className="text-ani"><a href="product/grilled-chicken/index.html"
                                                                    className="th-btn btn-sm style3 th_btn">Order Now</a>


                                                                </div> </Zoom>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-element elementor-element-bab5781 elementor-widget__width-initial elementor-widget-tablet__width-inherit elementor-widget elementor-widget-restaroffer"
                                                    data-id="bab5781" data-element_type="widget"
                                                    data-widget_type="restaroffer.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="cta-card cta-card1">
                                                            <div className="box-price">
                                                                <div className="bg"><img decoding="async"
                                                                    src="assets/images/shape_bg_1.svg"
                                                                    alt="shape_bg_1" /></div>
                                                                <div className="content">
                                                                    <p className="text">Only</p>
                                                                    <h6 className="price">&#8377;8</h6>
                                                                </div>
                                                            </div>
                                                            <Zoom>
                                                                <h3 className="box-title title-ani2">Delicious</h3>
                                                                <h4 className="box-heading title-ani2">Italian Pizza</h4>
                                                                <h4 className="box-text title-ani2">Limited Offer</h4>
                                                                <div className="text-ani"><a href="product/grilled-chicken/index.html"
                                                                    className="th-btn btn-sm style3 th_btn">Order Now</a></div>
                                                            </Zoom>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="elementor-element elementor-element-ba95375 e-con-full e-flex e-con e-parent main1"
                                        data-id="ba95375" data-element_type="container">
                                        <div className="elementor-element elementor-element-12c0df4 about-sec1 e-flex e-con-boxed e-con e-child"
                                            data-id="12c0df4" data-element_type="container">
                                            <div className="e-con-inner">
                                                <div className="elementor-element elementor-element-23449c7 e-con-full e-flex e-con e-child"
                                                    data-id="23449c7" data-element_type="container">
                                                    <div className="elementor-element elementor-element-e9dcb1f elementor-widget elementor-widget-restarimage"
                                                        data-id="e9dcb1f" data-element_type="widget"
                                                        data-widget_type="restarimage.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="img-box6 ">
                                                                <div className="shape1 ">
                                                                    <img decoding="async"
                                                                        src="assets/images/about_4_2.png"
                                                                        alt="about_4_2" data-speed="1.17" /></div>
                                                                <div className="img1"><Rotate ><img decoding="async"
                                                                    src="assets/images/product/about_4_1.png"
                                                                    alt="about_4_1" /></Rotate></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-element elementor-element-10990c9 e-con-full e-flex e-con e-child"
                                                    data-id="10990c9" data-element_type="container">
                                                    <div className="elementor-element elementor-element-d717381 elementor-widget elementor-widget-restarsectiontitle"
                                                        data-id="d717381" data-element_type="widget"
                                                        data-widget_type="restarsectiontitle.default">
                                                        <div className="elementor-widget-container">
                                                            <Zoom>   <div className="title-area   mb-35">
                                                                <span className="sub-title3 "><img decoding="async"
                                                                    src="assets/images/chef.png"
                                                                    alt="Shape" />ABOUT OUR FOOD</span>
                                                                <h2 className="sec-title  ">The Fine Art of Restar's Food Services</h2>
                                                                <div className="text-ani">
                                                                    <Fade direction='up'>  <p className="sec-text">Indulge in a gourmet journey with a menu
                                                                        that showcases a fusion of flavors. Our chefs use the finest
                                                                        ingredients to create dishes that are not just meals but
                                                                        unforgettable experiences.</p></Fade>
                                                                </div>
                                                            </div>
                                                            </Zoom>
                                                        </div>
                                                    </div>
                                                    <div className="elementor-element elementor-element-2e07346 elementor-widget elementor-widget-restarfeatures"
                                                        data-id="2e07346" data-element_type="widget"
                                                        data-widget_type="restarfeatures.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="about-feature-wrap">
                                                                <div className="about-feature text-ani">
                                                                    <div className="box-icon"><img decoding="async"
                                                                        src="assets/images/burger.png"
                                                                        alt="about_feature_1" /></div>
                                                                    <Zoom>   <div className="box-content">
                                                                        <h3 className="box-title">Best Quality Food</h3>
                                                                        <p className="box-text">The carefully curated décor sets the
                                                                            stage.</p>
                                                                    </div></Zoom>
                                                                </div>
                                                                <div className="about-feature text-ani">
                                                                    <div className="box-icon"><img decoding="async"
                                                                        src="assets/images/master-chef.png"
                                                                        alt="about_feature_2" /></div>
                                                                    <Zoom>     <div className="box-content">
                                                                        <h3 className="box-title">Qualified Chef</h3>
                                                                        <p className="box-text">Define the concept and theme of your
                                                                            food.</p>
                                                                    </div></Zoom>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="elementor-element elementor-element-a601a27 elementor-widget elementor-widget-restarbutton"
                                                        data-id="a601a27" data-element_type="widget"
                                                        data-widget_type="restarbutton.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="btn-wrapper">
                                                                <div className="text-ani"><a className="th-btn th_btn  style-border "
                                                                    href="reservation/index.html">Book A Table</a></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-1ac2379 e-con-full e-flex e-con e-parent main1"
                                        data-id="1ac2379" data-element_type="container">
                                        <div className="elementor-element elementor-element-4d1293c feature-sec1 e-flex e-con-boxed e-con e-child"
                                            data-id="4d1293c" data-element_type="container"
                                            data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                            <div className="e-con-inner">
                                                <div className="elementor-element elementor-element-341ec52 elementor-widget elementor-widget-restarfeatures"
                                                    data-id="341ec52" data-element_type="widget"
                                                    data-widget_type="restarfeatures.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="feature-card-wrap">
                                                            <div className="feature-card">
                                                                <div className="box-icon"><img decoding="async"
                                                                    src="assets/images/discount.png"
                                                                    alt="feature_card_1" /></div>
                                                                <Fade direction='up'>     <div className="box-content">
                                                                    <h3 className="box-title text-ani">Discount Voucher</h3>
                                                                    <p className="box-text text-ani">Authoritatively transition unique
                                                                        portals before.</p>
                                                                </div></Fade>
                                                            </div>
                                                            <div className="feature-card">
                                                                <div className="box-icon"><img decoding="async"
                                                                    src="assets/images/recipe.png"
                                                                    alt="feature_card_2" /></div>
                                                                <Fade direction='up'>   <div className="box-content">
                                                                    <h3 className="box-title text-ani">Original Recipes</h3>
                                                                    <p className="box-text text-ani">Authoritatively transition unique
                                                                        portals before.</p>
                                                                </div></Fade>
                                                            </div>
                                                            <div className="feature-card">
                                                                <div className="box-icon"><img decoding="async"
                                                                    src="assets/images/table-etiquette.png"
                                                                    alt="feature_card_3" /></div>
                                                                <Fade direction='up'>   <div className="box-content">
                                                                    <h3 className="box-title text-ani">Fast Serve On Table</h3>
                                                                    <p className="box-text text-ani">Authoritatively transition unique
                                                                        portals before.</p>
                                                                </div></Fade>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-106fa5d e-con-full e-flex e-con e-parent main1"
                                        data-id="106fa5d" data-element_type="container">
                                        <div className="elementor-element elementor-element-0109afe elementor-widget elementor-widget-restarshapeimage"
                                            data-id="0109afe" data-element_type="widget"
                                            data-widget_type="restarshapeimage.default">
                                            <div className="elementor-widget-container">
                                                {/* <!-- Image --> */}
                                                <div className="shape-mockup gspin spin d-lg-block" data-top="8%" data-left="3%"><img
                                                    decoding="async" src="assets/images/flower_9.png" data-speed=""
                                                    alt="Shape Image" /></div>
                                                {/* <!-- End Image --> */}
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-70753e4 elementor-widget elementor-widget-restarshapeimage"
                                            data-id="70753e4" data-element_type="widget"
                                            data-widget_type="restarshapeimage.default">
                                            <div className="elementor-widget-container">
                                                {/* <!-- Image --> */}
                                                <div className="shape-mockup  jump-reverse d-lg-block" data-bottom="8%"
                                                    data-right="0.01%"><img decoding="async"
                                                        src="assets/image/flower_6.png" data-speed="1.2"
                                                        alt="Shape Image" /></div>
                                                {/* <!-- End Image --> */}
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-4cb8e1b e-flex e-con-boxed e-con e-child"
                                            data-id="4cb8e1b" data-element_type="container">
                                            <div className="e-con-inner">
                                                <div className="elementor-element elementor-element-9f39805 e-con-full e-flex e-con e-child"
                                                    data-id="9f39805" data-element_type="container">
                                                    <div className="elementor-element elementor-element-474d1ac elementor-widget elementor-widget-restarsectiontitle"
                                                        data-id="474d1ac" data-element_type="widget"
                                                        data-widget_type="restarsectiontitle.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="title-area text-center ">
                                                                <Zoom>   <span className="sub-title3 "><img decoding="async"
                                                                    src="assets/images/chef.png"
                                                                    alt="Shape" />OUR MENU</span></Zoom>
                                                                <Fade direction='down' >    <h2 className="sec-title  ">Our Popular Foods Item</h2></Fade>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="elementor-element elementor-element-b8db37b elementor-widget elementor-widget-restareproduct"
                                                        data-id="b8db37b" data-element_type="widget"
                                                        data-widget_type="restareproduct.default">
                                                        <div className="elementor-widget-container">
                                                            <div className="text-ani">
                                                                <div className="tab-menu1 filter-menu-active"><button data-filter="*"
                                                                    className="th-btn style-border btn-sm active" type="button">All
                                                                    Foods</button><button className="th-btn style-border btn-sm"
                                                                        data-filter=".breakfast"
                                                                        type="button">Breakfast</button><button
                                                                            className="th-btn style-border btn-sm" data-filter=".dinner"
                                                                            type="button">Dinner</button><button
                                                                                className="th-btn style-border btn-sm" data-filter=".lunch"
                                                                                type="button">Lunch</button></div>
                                                            </div>
                                                            <div className="row gy-30 filter-active">
                                                                <div className="filter-item col-xl-3 col-lg-4 col-sm-6 breakfast ">
                                                                    <div className="th-product product-grid">
                                                                        <div className="product-img transparent-img">
                                                                            <a data-toggle="modal" data-target="#myModal">
                                                                                <img
                                                                                    fetchpriority="high" decoding="async" width="454"
                                                                                    height="454"
                                                                                    src="assets/images/product/product_1_13.png"
                                                                                    className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                                                                                    alt=""
                                                                                    sizes="(max-width: 454px) 100vw, 454px" />
                                                                            </a>
                                                                            <div className="actions"><a href="#"
                                                                                className="icon-btn woosq-btn woosq-btn-172 "
                                                                                data-id="172" data-effect="mfp-3d-unfold"><i
                                                                                    className="far fa-eye"></i></a><a
                                                                                        href="index7b34.html?add-to-cart=172"
                                                                                        data-quantity="1"
                                                                                        className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                        data-product_id="172"
                                                                                        data-product_sku="Breakfast-Platter"
                                                                                        aria-label="Add to cart: &ldquo;Breakfast Platter&rdquo;"
                                                                                        rel="nofollow"><i
                                                                                            className="far fa-cart-plus"></i></a>
                                                                                <div className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-the_content"
                                                                                    data-tinvwl_product_id="172">
                                                                                    <a role="button" tabindex="0" name=""
                                                                                        aria-label=""
                                                                                        className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                                                                        data-tinv-wl-list="[]"
                                                                                        data-tinv-wl-product="172"
                                                                                        data-tinv-wl-productvariation="0"
                                                                                        data-tinv-wl-productvariations="[]"
                                                                                        data-tinv-wl-producttype="simple"
                                                                                        data-tinv-wl-action="add"></a>
                                                                                    <div className="tinvwl-tooltip"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="product-content">
                                                                            <div className="woocommerce-product-rating">
                                                                                <div className="star-rating" role="img"
                                                                                    aria-label="Rated 5.00 out of 5"><span>Rated <strong
                                                                                        className="rating">5.00</strong> out of
                                                                                        5</span>
                                                                                </div>
                                                                            </div>
                                                                            <h3 className="product-title">
                                                                                <a data-toggle="modal" data-target="#myModal">
                                                                                    Breakfast
                                                                                    Platter</a></h3>
                                                                            <span className="product-price price"><span
                                                                                className="woocommerce-Price-amount amount"><span
                                                                                    className="woocommerce-Price-currencySymbol">&#8377;</span>20</span></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="filter-item col-xl-3 col-lg-4 col-sm-6 breakfast ">
                                                                    <div className="th-product product-grid">
                                                                        <div className="product-img transparent-img">
                                                                            <a data-toggle="modal" data-target="#myModal">
                                                                                <img decoding="async" width="454" height="454"
                                                                                    src="assets/images/product/product_1_11.png"
                                                                                    className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                                                                                    alt=""
                                                                                    sizes="(max-width: 454px) 100vw, 454px" />
                                                                            </a>
                                                                            <div className="actions"><a href="#"
                                                                                className="icon-btn woosq-btn woosq-btn-170 "
                                                                                data-id="170" data-effect="mfp-3d-unfold"><i
                                                                                    className="far fa-eye"></i></a><a
                                                                                        href="index2139.html?add-to-cart=170"
                                                                                        data-quantity="1"
                                                                                        className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                        data-product_id="170"
                                                                                        data-product_sku="Butter-Pasta"
                                                                                        aria-label="Add to cart: &ldquo;Butter Pasta&rdquo;"
                                                                                        rel="nofollow"><i
                                                                                            className="far fa-cart-plus"></i></a>
                                                                                <div className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-the_content"
                                                                                    data-tinvwl_product_id="170">
                                                                                    <a role="button" tabindex="0" name=""
                                                                                        aria-label=""
                                                                                        className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                                                                        data-tinv-wl-list="[]"
                                                                                        data-tinv-wl-product="170"
                                                                                        data-tinv-wl-productvariation="0"
                                                                                        data-tinv-wl-productvariations="[]"
                                                                                        data-tinv-wl-producttype="simple"
                                                                                        data-tinv-wl-action="add"></a>
                                                                                    <div className="tinvwl-tooltip"></div>
                                                                                </div>
                                                                            </div><span className="product-tag">Sale</span>
                                                                        </div>
                                                                        <div className="product-content">
                                                                            <div className="woocommerce-product-rating">
                                                                                <div className="star-rating" role="img"
                                                                                    aria-label="Rated 5.00 out of 5"><span>Rated <strong
                                                                                        className="rating">5.00</strong> out of
                                                                                        5</span></div>
                                                                            </div>
                                                                            <h3 className="product-title"><a data-toggle="modal" data-target="#myModal">Butter
                                                                                Pasta</a></h3>
                                                                            <span className="product-price price"> <ins><span
                                                                                className="woocommerce-Price-amount amount"><span
                                                                                    className="woocommerce-Price-currencySymbol">&#8377;</span>18</span></ins>
                                                                                <del><span
                                                                                    className="woocommerce-Price-amount amount"><span
                                                                                        className="woocommerce-Price-currencySymbol">&#8377;</span>20</span></del></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="filter-item col-xl-3 col-lg-4 col-sm-6 lunch ">
                                                                    <div className="th-product product-grid">
                                                                        <div className="product-img transparent-img">
                                                                            <a data-toggle="modal" data-target="#myModal">
                                                                                <img
                                                                                    decoding="async" width="454" height="454"
                                                                                    src="assets/images/product/product_1_6.png"
                                                                                    className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                                                                                    alt=""
                                                                                    sizes="(max-width: 454px) 100vw, 454px" />
                                                                            </a>
                                                                            <div className="actions"><a href="#"
                                                                                className="icon-btn woosq-btn woosq-btn-165 "
                                                                                data-id="165" data-effect="mfp-3d-unfold"><i
                                                                                    className="far fa-eye"></i></a><a
                                                                                        href="indexc37d.html?add-to-cart=165"
                                                                                        data-quantity="1"
                                                                                        className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                        data-product_id="165"
                                                                                        data-product_sku="Chicken-Fried-Rice"
                                                                                        aria-label="Add to cart: &ldquo;Chicken Fried Rice&rdquo;"
                                                                                        rel="nofollow"><i
                                                                                            className="far fa-cart-plus"></i></a>
                                                                                <div className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-the_content"
                                                                                    data-tinvwl_product_id="165">
                                                                                    <a role="button" tabindex="0" name=""
                                                                                        aria-label=""
                                                                                        className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                                                                        data-tinv-wl-list="[]"
                                                                                        data-tinv-wl-product="165"
                                                                                        data-tinv-wl-productvariation="0"
                                                                                        data-tinv-wl-productvariations="[]"
                                                                                        data-tinv-wl-producttype="simple"
                                                                                        data-tinv-wl-action="add"></a>
                                                                                    <div className="tinvwl-tooltip"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="product-content">
                                                                            <div className="woocommerce-product-rating">
                                                                                <div className="star-rating" role="img"
                                                                                    aria-label="Rated 5.00 out of 5"><span>Rated <strong
                                                                                        className="rating">5.00</strong> out of
                                                                                        5</span></div>
                                                                            </div>
                                                                            <h3 className="product-title"><a data-toggle="modal" data-target="#myModal">Chicken
                                                                                Fried Rice</a></h3>
                                                                            <span className="product-price price"><span
                                                                                className="woocommerce-Price-amount amount"><span
                                                                                    className="woocommerce-Price-currencySymbol">&#8377;</span>29</span></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="filter-item col-xl-3 col-lg-4 col-sm-6 dinner ">
                                                                    <div className="th-product product-grid">
                                                                        <div className="product-img transparent-img">
                                                                            <a data-toggle="modal" data-target="#myModal">
                                                                                <img loading="lazy"
                                                                                    decoding="async" width="454" height="454"
                                                                                    src="assets/images/product/product_1_9.png"
                                                                                    className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                                                                                    alt=""
                                                                                    sizes="(max-width: 454px) 100vw, 454px" />
                                                                            </a>
                                                                            <div className="actions"><a href="#"
                                                                                className="icon-btn woosq-btn woosq-btn-168 "
                                                                                data-id="168" data-effect="mfp-3d-unfold"><i
                                                                                    className="far fa-eye"></i></a><a
                                                                                        href="index0a67.html?add-to-cart=168"
                                                                                        data-quantity="1"
                                                                                        className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                        data-product_id="168"
                                                                                        data-product_sku="Chicken-Leg-Piece"
                                                                                        aria-label="Add to cart: &ldquo;Chicken Leg Piece&rdquo;"
                                                                                        rel="nofollow"><i
                                                                                            className="far fa-cart-plus"></i></a>
                                                                                <div className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-the_content"
                                                                                    data-tinvwl_product_id="168">
                                                                                    <a role="button" tabindex="0" name=""
                                                                                        aria-label=""
                                                                                        className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                                                                        data-tinv-wl-list="[]"
                                                                                        data-tinv-wl-product="168"
                                                                                        data-tinv-wl-productvariation="0"
                                                                                        data-tinv-wl-productvariations="[]"
                                                                                        data-tinv-wl-producttype="simple"
                                                                                        data-tinv-wl-action="add"></a>
                                                                                    <div className="tinvwl-tooltip"></div>
                                                                                </div>
                                                                            </div><span className="product-tag">Sale</span>
                                                                        </div>
                                                                        <div className="product-content">
                                                                            <div className="woocommerce-product-rating">
                                                                                <div className="star-rating" role="img"
                                                                                    aria-label="Rated 5.00 out of 5"><span>Rated <strong
                                                                                        className="rating">5.00</strong> out of
                                                                                        5</span></div>
                                                                            </div>
                                                                            <h3 className="product-title"><a data-toggle="modal" data-target="#myModal">Chicken
                                                                                Leg Piece</a></h3>
                                                                            <span className="product-price price"> <ins><span
                                                                                className="woocommerce-Price-amount amount"><span
                                                                                    className="woocommerce-Price-currencySymbol">&#8377;</span>25</span></ins>
                                                                                <del><span
                                                                                    className="woocommerce-Price-amount amount"><span
                                                                                        className="woocommerce-Price-currencySymbol">&#8377;</span>30</span></del></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="filter-item col-xl-3 col-lg-4 col-sm-6 breakfast dinner ">
                                                                    <div className="th-product product-grid">
                                                                        <div className="product-img transparent-img">
                                                                            <a data-toggle="modal" data-target="#myModal">
                                                                                <img loading="lazy"
                                                                                    decoding="async" width="454" height="454"
                                                                                    src="assets/images/product/product_1_8.png"
                                                                                    className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                                                                                    alt=""
                                                                                    sizes="(max-width: 454px) 100vw, 454px" />
                                                                            </a>
                                                                            <div className="actions"><a href="#"
                                                                                className="icon-btn woosq-btn woosq-btn-167 "
                                                                                data-id="167" data-effect="mfp-3d-unfold"><i
                                                                                    className="far fa-eye"></i></a><a
                                                                                        href="index8c8a.html?add-to-cart=167"
                                                                                        data-quantity="1"
                                                                                        className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                        data-product_id="167"
                                                                                        data-product_sku="Chicken-with-Rice"
                                                                                        aria-label="Add to cart: &ldquo;Chicken with Rice&rdquo;"
                                                                                        rel="nofollow"><i
                                                                                            className="far fa-cart-plus"></i></a>
                                                                                <div className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-the_content"
                                                                                    data-tinvwl_product_id="167">
                                                                                    <a role="button" tabindex="0" name=""
                                                                                        aria-label=""
                                                                                        className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                                                                        data-tinv-wl-list="[]"
                                                                                        data-tinv-wl-product="167"
                                                                                        data-tinv-wl-productvariation="0"
                                                                                        data-tinv-wl-productvariations="[]"
                                                                                        data-tinv-wl-producttype="simple"
                                                                                        data-tinv-wl-action="add"></a>
                                                                                    <div className="tinvwl-tooltip"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="product-content">
                                                                            <div className="woocommerce-product-rating">
                                                                                <div className="star-rating" role="img"
                                                                                    aria-label="Rated 5.00 out of 5"><span>Rated <strong
                                                                                        className="rating">5.00</strong> out of
                                                                                        5</span></div>
                                                                            </div>
                                                                            <h3 className="product-title"><a data-toggle="modal" data-target="#myModal">Chicken
                                                                                with Rice</a></h3>
                                                                            <span className="product-price price"><span
                                                                                className="woocommerce-Price-amount amount"><span
                                                                                    className="woocommerce-Price-currencySymbol">&#8377;</span>30</span></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="filter-item col-xl-3 col-lg-4 col-sm-6 lunch ">
                                                                    <div className="th-product product-grid">
                                                                        <div className="product-img transparent-img">
                                                                            <a data-toggle="modal" data-target="#myModal">
                                                                                <img loading="lazy"
                                                                                    decoding="async" width="454" height="454"
                                                                                    src="assets/images/product/product_1_1.png"
                                                                                    className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                                                                                    alt=""
                                                                                    sizes="(max-width: 454px) 100vw, 454px" />
                                                                            </a>
                                                                            <div className="actions"><a href="#"
                                                                                className="icon-btn woosq-btn woosq-btn-161 "
                                                                                data-id="161" data-effect="mfp-3d-unfold"><i
                                                                                    className="far fa-eye"></i></a><a
                                                                                        href="index1038.html?add-to-cart=161"
                                                                                        data-quantity="1"
                                                                                        className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                        data-product_id="161"
                                                                                        data-product_sku="Egg-and-Cocumber"
                                                                                        aria-label="Add to cart: &ldquo;Egg and Cocumber&rdquo;"
                                                                                        rel="nofollow"><i
                                                                                            className="far fa-cart-plus"></i></a>
                                                                                <div className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-the_content"
                                                                                    data-tinvwl_product_id="161">
                                                                                    <a role="button" tabindex="0" name=""
                                                                                        aria-label=""
                                                                                        className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                                                                        data-tinv-wl-list="[]"
                                                                                        data-tinv-wl-product="161"
                                                                                        data-tinv-wl-productvariation="0"
                                                                                        data-tinv-wl-productvariations="[]"
                                                                                        data-tinv-wl-producttype="simple"
                                                                                        data-tinv-wl-action="add"></a>
                                                                                    <div className="tinvwl-tooltip"></div>
                                                                                </div>
                                                                            </div><span className="product-tag">Sale</span>
                                                                        </div>
                                                                        <div className="product-content">
                                                                            <div className="woocommerce-product-rating">
                                                                                <div className="star-rating" role="img"
                                                                                    aria-label="Rated 5.00 out of 5"><span>Rated <strong
                                                                                        className="rating">5.00</strong> out of
                                                                                        5</span></div>
                                                                            </div>
                                                                            <h3 className="product-title"><a data-toggle="modal" data-target="#myModal">Egg
                                                                                and Cocumber</a></h3>
                                                                            <span className="product-price price"> <ins><span
                                                                                className="woocommerce-Price-amount amount"><span
                                                                                    className="woocommerce-Price-currencySymbol">&#8377;</span>28</span></ins>
                                                                                <del><span
                                                                                    className="woocommerce-Price-amount amount"><span
                                                                                        className="woocommerce-Price-currencySymbol">&#8377;</span>32</span></del></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="filter-item col-xl-3 col-lg-4 col-sm-6 breakfast ">
                                                                    <div className="th-product product-grid">
                                                                        <div className="product-img transparent-img">
                                                                            <a data-toggle="modal" data-target="#myModal">
                                                                                <img loading="lazy"
                                                                                    decoding="async" width="454" height="454"
                                                                                    src="assets/images/product/product_1_10.png"
                                                                                    className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                                                                                    alt=""
                                                                                    sizes="(max-width: 454px) 100vw, 454px" />
                                                                            </a>
                                                                            <div className="actions"><a href="#"
                                                                                className="icon-btn woosq-btn woosq-btn-169 "
                                                                                data-id="169" data-effect="mfp-3d-unfold"><i
                                                                                    className="far fa-eye"></i></a><a
                                                                                        href="index939e.html?add-to-cart=169"
                                                                                        data-quantity="1"
                                                                                        className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                        data-product_id="169"
                                                                                        data-product_sku="Grilled-Chicken"
                                                                                        aria-label="Add to cart: &ldquo;Grilled Chicken&rdquo;"
                                                                                        rel="nofollow"><i
                                                                                            className="far fa-cart-plus"></i></a>
                                                                                <div className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-the_content"
                                                                                    data-tinvwl_product_id="169">
                                                                                    <a role="button" tabindex="0" name=""
                                                                                        aria-label=""
                                                                                        className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                                                                        data-tinv-wl-list="[]"
                                                                                        data-tinv-wl-product="169"
                                                                                        data-tinv-wl-productvariation="0"
                                                                                        data-tinv-wl-productvariations="[]"
                                                                                        data-tinv-wl-producttype="simple"
                                                                                        data-tinv-wl-action="add"></a>
                                                                                    <div className="tinvwl-tooltip"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="product-content">
                                                                            <div className="woocommerce-product-rating">
                                                                                <div className="star-rating" role="img"
                                                                                    aria-label="Rated 5.00 out of 5"><span>Rated <strong
                                                                                        className="rating">5.00</strong> out of
                                                                                        5</span></div>
                                                                            </div>
                                                                            <h3 className="product-title"><a data-toggle="modal" data-target="#myModal">Grilled
                                                                                Chicken</a></h3>
                                                                            <span className="product-price price"><span
                                                                                className="woocommerce-Price-amount amount"><span
                                                                                    className="woocommerce-Price-currencySymbol">&#8377;</span>20</span></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="filter-item col-xl-3 col-lg-4 col-sm-6 dinner ">
                                                                    <div className="th-product product-grid">
                                                                        <div className="product-img transparent-img">
                                                                            <a data-toggle="modal" data-target="#myModal">
                                                                                <img loading="lazy"
                                                                                    decoding="async" width="454" height="454"
                                                                                    src="assets/images/product/product_1_14.png"
                                                                                    className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                                                                                    alt=""
                                                                                    sizes="(max-width: 454px) 100vw, 454px" />
                                                                            </a>
                                                                            <div className="actions"><a href="#"
                                                                                className="icon-btn woosq-btn woosq-btn-175 "
                                                                                data-id="175" data-effect="mfp-3d-unfold"><i
                                                                                    className="far fa-eye"></i></a><a
                                                                                        href="index3e47.html?add-to-cart=175"
                                                                                        data-quantity="1"
                                                                                        className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                        data-product_id="175"
                                                                                        data-product_sku="Kozo-Sushi-Platter"
                                                                                        aria-label="Add to cart: &ldquo;Kozo Sushi Platter&rdquo;"
                                                                                        rel="nofollow"><i
                                                                                            className="far fa-cart-plus"></i></a>
                                                                                <div className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-the_content"
                                                                                    data-tinvwl_product_id="175">
                                                                                    <a role="button" tabindex="0" name=""
                                                                                        aria-label=""
                                                                                        className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                                                                        data-tinv-wl-list="[]"
                                                                                        data-tinv-wl-product="175"
                                                                                        data-tinv-wl-productvariation="0"
                                                                                        data-tinv-wl-productvariations="[]"
                                                                                        data-tinv-wl-producttype="simple"
                                                                                        data-tinv-wl-action="add"></a>
                                                                                    <div className="tinvwl-tooltip"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="product-content">
                                                                            <div className="woocommerce-product-rating">
                                                                                <div className="star-rating" role="img"
                                                                                    aria-label="Rated 5.00 out of 5"><span>Rated <strong
                                                                                        className="rating">5.00</strong> out of
                                                                                        5</span></div>
                                                                            </div>
                                                                            <h3 className="product-title"><a data-toggle="modal" data-target="#myModal">Kozo
                                                                                Sushi Platter</a></h3>
                                                                            <span className="product-price price"><span
                                                                                className="woocommerce-Price-amount amount"><span
                                                                                    className="woocommerce-Price-currencySymbol">&#8377;</span>20</span></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4 text-center shop-detail-btn">
                                                                <Link to="/kitchens" className="th-btn th_btn slideinup">        View all</Link>
                                                            </div>



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-01d4b1b e-con-full e-flex e-con e-parent"
                                        data-id="01d4b1b" data-element_type="container"
                                        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div className="elementor-element elementor-element-1c7d754 elementor-widget elementor-widget-restarshapeimage"
                                            data-id="1c7d754" data-element_type="widget"
                                            data-widget_type="restarshapeimage.default">
                                            <div className="elementor-widget-container">
                                                {/* <!-- Image --> */}
                                                <div className="shape-mockup  jump-reverse d-xl-block d-none" data-bottom="-10%"
                                                    data-left="0.01%"><img decoding="async"
                                                        src="assets/images/pata.png" data-speed="1.17"
                                                        alt="Shape Image" /></div>
                                                {/* <!-- End Image --> */}
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-7f4e56c elementor-widget elementor-widget-restarshapeimage"
                                            data-id="7f4e56c" data-element_type="widget"
                                            data-widget_type="restarshapeimage.default">
                                            <div className="elementor-widget-container">
                                                {/* <!-- Image --> */}
                                                <div className="shape-mockup  jump d-xl-block" data-bottom="10%" data-right="0.01%"><img
                                                    decoding="async" src="assets/images/tomato_2.png"
                                                    data-speed="1.12" alt="Shape Image" /></div>
                                                {/* <!-- End Image --> */}
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-b84e1db e-flex e-con-boxed e-con e-child"
                                            data-id="b84e1db" data-element_type="container">
                                            <div className="e-con-inner">
                                                <div className="elementor-element elementor-element-7e900f7 e-con-full e-flex e-con e-child"
                                                    data-id="7e900f7" data-element_type="container">
                                                    <div className="elementor-element elementor-element-0ca61d1 e-con-full text-xl-start text-center e-flex e-con e-child"
                                                        data-id="0ca61d1" data-element_type="container">
                                                        <div className="elementor-element elementor-element-52b1af0 elementor-widget elementor-widget-text-editor"
                                                            data-id="52b1af0" data-element_type="widget"
                                                            data-widget_type="text-editor.default">
                                                            <div className="elementor-widget-container">
                                                                <Fade direction='down'>  <h4 className="text-theme title-ani2 mb-35">Get 30% Discount</h4></Fade>
                                                            </div>
                                                        </div>
                                                        <div className="elementor-element elementor-element-cc1f700 elementor-widget elementor-widget-text-editor"
                                                            data-id="cc1f700" data-element_type="widget"
                                                            data-widget_type="text-editor.default">
                                                            <div className="elementor-widget-container">
                                                                <Fade direction='down'>        <h2 className="sec-title title-ani2 h1 text-white mb-30">Grill Chicken
                                                                    Stick</h2></Fade>
                                                            </div>
                                                        </div>
                                                        <div className="elementor-element elementor-element-12063c1 elementor-widget elementor-widget-text-editor"
                                                            data-id="12063c1" data-element_type="widget"
                                                            data-widget_type="text-editor.default">
                                                            <div className="elementor-widget-container">
                                                                <Fade direction='down'>    <h2 className="sec-title title-ani2 text-white mb-32">Limited Offer</h2></Fade>
                                                            </div>
                                                        </div>
                                                        <div className="elementor-element elementor-element-209c38e elementor-widget elementor-widget-restarbutton"
                                                            data-id="209c38e" data-element_type="widget"
                                                            data-widget_type="restarbutton.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="btn-wrapper">
                                                                    <Fade direction='down'>   <div className="text-ani"><a className="th-btn th_btn style3"
                                                                        href="shop/index.html">Order Now</a></div></Fade>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="elementor-element elementor-element-65fe9f3 e-con-full e-flex e-con e-child"
                                                        data-id="65fe9f3" data-element_type="container">
                                                        <div className="elementor-element elementor-element-d2d18d8 elementor-widget elementor-widget-restarimage"
                                                            data-id="d2d18d8" data-element_type="widget"
                                                            data-widget_type="restarimage.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="cta-img1">
                                                                    <div className="img1"><img decoding="async"
                                                                        src="assets/images/cta_1.png" alt="cta_1" />
                                                                    </div>
                                                                    <h4 className="price"
                                                                        data-bg-src="assets/images/shape_bg_2.svg">
                                                                        &#8377;30</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-557ecf8 e-con-full e-flex e-con e-parent"
                                        data-id="557ecf8" data-element_type="container">
                                        <div className="elementor-element elementor-element-f125196 e-flex e-con-boxed e-con e-child"
                                            data-id="f125196" data-element_type="container">
                                            <div className="e-con-inner">
                                                <div className="elementor-element elementor-element-8a41dce e-con-full e-flex e-con e-child"
                                                    data-id="8a41dce" data-element_type="container">
                                                    <div
                                                        className="elementor-element elementor-element-5cf69fb e-con-full e-flex e-con e-child"
                                                        data-id="5cf69fb"
                                                        data-element_type="container"
                                                    >
                                                        <div
                                                            className="elementor-element elementor-element-62cc056 elementor-widget elementor-widget-restarimage"
                                                            data-id="62cc056"
                                                            data-element_type="widget"
                                                            data-widget_type="restarimage.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="img-box7">
                                                                    <div className="shape1">
                                                                        <img
                                                                            decoding="async"
                                                                            src="assets/images/delivery_2.png"
                                                                            alt="delivery_2"

                                                                        />
                                                                    </div>
                                                                    <Zoom  > <div className="img1 ">
                                                                        <img
                                                                            decoding="async"
                                                                            src="assets/images/delivery_1.png"
                                                                            alt="delivery_1"
                                                                        />
                                                                    </div></Zoom>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="elementor-element elementor-element-0f6c2c6 e-con-full e-flex e-con e-child"
                                                        data-id="0f6c2c6" data-element_type="container">
                                                        <div className="elementor-element elementor-element-169de8f elementor-widget elementor-widget-restarsectiontitle"
                                                            data-id="169de8f" data-element_type="widget"
                                                            data-widget_type="restarsectiontitle.default">
                                                            <div className="elementor-widget-container">
                                                                <Fade direction='up'>   <div className="title-area  ">
                                                                    <span className="sub-title3 "><img decoding="async"
                                                                        src="assets/images/chef.png"
                                                                        alt="Shape" />FOOD DELIVERY</span>
                                                                    <h2 className="sec-title  ">Where Good Food Meets Fast Delivery</h2>
                                                                    <div className="text-ani">
                                                                        <p className="sec-text">Indulge in a gourmet journey with a menu
                                                                            that showcases a fusion of flavors. Our chefs use the
                                                                            finest ingredients to create dishes that are not just
                                                                            meals but unforgettable experiences.</p>
                                                                    </div>
                                                                </div></Fade>

                                                            </div>
                                                        </div>
                                                        <div className="elementor-element elementor-element-ae06c2c elementor-widget elementor-widget-restarfeatures"
                                                            data-id="ae06c2c" data-element_type="widget"
                                                            data-widget_type="restarfeatures.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="check-feature-wrap">
                                                                    <Fade direction='up'> <div className="check-feature text-ani"><i
                                                                        className="fa-solid fa-badge-check"></i> Delivery In 30
                                                                        Minutes</div></Fade>
                                                                    <Fade direction='up'>   <div className="check-feature text-ani"><i
                                                                        className="fa-solid fa-badge-check"></i> Free Shipping
                                                                        &#8377;50.00</div></Fade>
                                                                    <Fade direction='up'>       <div className="check-feature text-ani"><i
                                                                        className="fa-solid fa-badge-check"></i> Delivery On
                                                                        Doorstep</div></Fade>
                                                                    <Fade direction='up'>       <div className="check-feature text-ani"><i
                                                                        className="fa-solid fa-badge-check"></i> On the Way Tracing
                                                                    </div></Fade>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="elementor-element elementor-element-89c0961 elementor-widget elementor-widget-restarbutton"
                                                            data-id="89c0961" data-element_type="widget"
                                                            data-widget_type="restarbutton.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="btn-wrapper"><a className="th-btn th_btn "
                                                                    href="shop/index.html">Order Now</a></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element elementor-element-06b354d e-con-full e-flex e-con e-parent"
                                        data-id="06b354d" data-element_type="container">
                                        <div className="elementor-element elementor-element-cc95751 menu-sec4 e-flex e-con-boxed e-con e-child"
                                            data-id="cc95751" data-element_type="container"
                                            data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                            <div className="e-con-inner">
                                                <div className="elementor-element elementor-element-847f21a elementor-widget elementor-widget-restarsectiontitle"
                                                    data-id="847f21a" data-element_type="widget"
                                                    data-widget_type="restarsectiontitle.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="title-area text-center ">
                                                            <Fade direction='down'>   <span className="sub-title3 "><img decoding="async"
                                                                src="assets/images/chef.png"
                                                                alt="Shape" />OUR MENU</span>
                                                                <h2 className="sec-title  ">Our Restaurant Foods Menu</h2></Fade>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="elementor-element elementor-element-83d40cc elementor-widget elementor-widget-restareproduct"
                                                    data-id="83d40cc" data-element_type="widget"
                                                    data-widget_type="restareproduct.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="row gy-30">
                                                            <div className="col-xl-6">
                                                                <div className="menu-list-block">
                                                                    <div className="menu-list">
                                                                        <div className="box-img">
                                                                            <a data-toggle="modal" data-target="#myModal">
                                                                                <img loading="lazy" decoding="async"
                                                                                    width="100" height="100"
                                                                                    src="assets/images/product/product_1_13-100x100.png"
                                                                                    className="attachment-restar-shop-thumb size-restar-shop-thumb wp-post-image"
                                                                                    alt=""
                                                                                    sizes="(max-width: 100px) 100vw, 100px" />
                                                                            </a>
                                                                            <div className="actions">
                                                                                <a href="index7b34.html?add-to-cart=172"
                                                                                    data-quantity="1"
                                                                                    className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                    data-product_id="172"
                                                                                    data-product_sku="Breakfast-Platter"
                                                                                    aria-label="Add to cart: &ldquo;Breakfast Platter&rdquo;"
                                                                                    rel="nofollow"><i className="far fa-cart-plus"></i>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h3 className="box-title el-title">
                                                                                <a data-toggle="modal" data-target="#myModal">
                                                                                    Breakfast
                                                                                    Platter
                                                                                </a>
                                                                            </h3>
                                                                            <div className="box-content">
                                                                                <p className="box-text">The registration fee covers
                                                                                    access</p>
                                                                                <div className="box-line"></div>
                                                                                <h4 className="box-price el-price"><span
                                                                                    className="woocommerce-Price-amount amount"><bdi><span
                                                                                        className="woocommerce-Price-currencySymbol">&#8377;</span>20</bdi></span>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="menu-list">
                                                                        <div className="box-img"><img loading="lazy" decoding="async"
                                                                            width="100" height="100"
                                                                            src="assets/images/product/product_1_12-100x100.png"
                                                                            className="attachment-restar-shop-thumb size-restar-shop-thumb wp-post-image"
                                                                            alt=""
                                                                            sizes="(max-width: 100px) 100vw, 100px" />
                                                                            <div className="actions"><a
                                                                                href="index5d5d.html?add-to-cart=171"
                                                                                data-quantity="1"
                                                                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                data-product_id="171"
                                                                                data-product_sku="Vegetable-Salad"
                                                                                aria-label="Add to cart: &ldquo;Vegetable Salad&rdquo;"
                                                                                rel="nofollow"><i
                                                                                    className="far fa-cart-plus"></i></a></div>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h3 className="box-title el-title"><a
                                                                                href="product/vegetable-salad/index.html">Vegetable
                                                                                Salad</a></h3>
                                                                            <div className="box-content">
                                                                                <p className="box-text">The registration fee covers
                                                                                    access</p>
                                                                                <div className="box-line"></div>
                                                                                <h4 className="box-price el-price"><span
                                                                                    className="woocommerce-Price-amount amount"><bdi><span
                                                                                        className="woocommerce-Price-currencySymbol">&#8377;</span>20</bdi></span>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="menu-list">
                                                                        <div className="box-img"><img loading="lazy" decoding="async"
                                                                            width="100" height="100"
                                                                            src="assets/images/product/product_1_11-100x100.png"
                                                                            className="attachment-restar-shop-thumb size-restar-shop-thumb wp-post-image"
                                                                            alt=""
                                                                            sizes="(max-width: 100px) 100vw, 100px" />
                                                                            <div className="actions"><a
                                                                                href="index2139.html?add-to-cart=170"
                                                                                data-quantity="1"
                                                                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                data-product_id="170"
                                                                                data-product_sku="Butter-Pasta"
                                                                                aria-label="Add to cart: &ldquo;Butter Pasta&rdquo;"
                                                                                rel="nofollow"><i
                                                                                    className="far fa-cart-plus"></i></a></div>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h3 className="box-title el-title"><a
                                                                                href="product/butter-pasta/index.html">Butter
                                                                                Pasta</a></h3>
                                                                            <div className="box-content">
                                                                                <p className="box-text">The registration fee covers
                                                                                    access</p>
                                                                                <div className="box-line"></div>
                                                                                <h4 className="box-price el-price"> <ins><span
                                                                                    className="woocommerce-Price-amount amount"><bdi><span
                                                                                        className="woocommerce-Price-currencySymbol">&#8377;</span>18</bdi></span></ins>
                                                                                    <del><span
                                                                                        className="woocommerce-Price-amount amount"><bdi><span
                                                                                            className="woocommerce-Price-currencySymbol">&#8377;</span>20</bdi></span></del>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="menu-list">
                                                                        <div className="box-img"><img loading="lazy" decoding="async"
                                                                            width="100" height="100"
                                                                            src="assets/images/product/product_1_10-100x100.png"
                                                                            className="attachment-restar-shop-thumb size-restar-shop-thumb wp-post-image"
                                                                            alt=""
                                                                            sizes="(max-width: 100px) 100vw, 100px" />
                                                                            <div className="actions"><a
                                                                                href="index939e.html?add-to-cart=169"
                                                                                data-quantity="1"
                                                                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                data-product_id="169"
                                                                                data-product_sku="Grilled-Chicken"
                                                                                aria-label="Add to cart: &ldquo;Grilled Chicken&rdquo;"
                                                                                rel="nofollow"><i
                                                                                    className="far fa-cart-plus"></i></a></div>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h3 className="box-title el-title"><a
                                                                                href="product/grilled-chicken/index.html">Grilled
                                                                                Chicken</a></h3>
                                                                            <div className="box-content">
                                                                                <p className="box-text">The registration fee covers
                                                                                    access</p>
                                                                                <div className="box-line"></div>
                                                                                <h4 className="box-price el-price"><span
                                                                                    className="woocommerce-Price-amount amount"><bdi><span
                                                                                        className="woocommerce-Price-currencySymbol">&#8377;</span>20</bdi></span>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="menu-list">
                                                                        <div className="box-img"><img loading="lazy" decoding="async"
                                                                            width="100" height="100"
                                                                            src="assets/images/product/product_1_8-100x100.png"
                                                                            className="attachment-restar-shop-thumb size-restar-shop-thumb wp-post-image"
                                                                            alt=""
                                                                            sizes="(max-width: 100px) 100vw, 100px" />
                                                                            <div className="actions"><a
                                                                                href="index8c8a.html?add-to-cart=167"
                                                                                data-quantity="1"
                                                                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                data-product_id="167"
                                                                                data-product_sku="Chicken-with-Rice"
                                                                                aria-label="Add to cart: &ldquo;Chicken with Rice&rdquo;"
                                                                                rel="nofollow"><i
                                                                                    className="far fa-cart-plus"></i></a></div>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h3 className="box-title el-title"><a
                                                                                href="product/chicken-with-rice/index.html">Chicken
                                                                                with Rice</a></h3>
                                                                            <div className="box-content">
                                                                                <p className="box-text">The registration fee covers
                                                                                    access</p>
                                                                                <div className="box-line"></div>
                                                                                <h4 className="box-price el-price"><span
                                                                                    className="woocommerce-Price-amount amount"><bdi><span
                                                                                        className="woocommerce-Price-currencySymbol">&#8377;</span>30</bdi></span>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="menu-list">
                                                                        <div className="box-img"><img loading="lazy" decoding="async"
                                                                            width="100" height="100"
                                                                            src="assets/images/product/product_1_4-100x100.png"
                                                                            className="attachment-restar-shop-thumb size-restar-shop-thumb wp-post-image"
                                                                            alt=""
                                                                            sizes="(max-width: 100px) 100vw, 100px" />
                                                                            <div className="actions"><a
                                                                                href="indexedca.html?add-to-cart=164"
                                                                                data-quantity="1"
                                                                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                data-product_id="164"
                                                                                data-product_sku="Raw-Salmon-Salad"
                                                                                aria-label="Add to cart: &ldquo;Raw Salmon Salad&rdquo;"
                                                                                rel="nofollow"><i
                                                                                    className="far fa-cart-plus"></i></a></div>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h3 className="box-title el-title"><a
                                                                                href="product/raw-salmon-salad/index.html">Raw
                                                                                Salmon Salad</a></h3>
                                                                            <div className="box-content">
                                                                                <p className="box-text">The registration fee covers
                                                                                    access</p>
                                                                                <div className="box-line"></div>
                                                                                <h4 className="box-price el-price"> <ins><span
                                                                                    className="woocommerce-Price-amount amount"><bdi><span
                                                                                        className="woocommerce-Price-currencySymbol">&#8377;</span>18</bdi></span></ins>
                                                                                    <del><span
                                                                                        className="woocommerce-Price-amount amount"><bdi><span
                                                                                            className="woocommerce-Price-currencySymbol">&#8377;</span>22</bdi></span></del>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="menu-list-block">
                                                                    <div className="menu-list">
                                                                        <div className="box-img"><img loading="lazy" decoding="async"
                                                                            width="100" height="100"
                                                                            src="assets/images/product/product_1_14-100x100.png"
                                                                            className="attachment-restar-shop-thumb size-restar-shop-thumb wp-post-image"
                                                                            alt=""
                                                                            sizes="(max-width: 100px) 100vw, 100px" />
                                                                            <div className="actions"><a
                                                                                href="index3e47.html?add-to-cart=175"
                                                                                data-quantity="1"
                                                                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                data-product_id="175"
                                                                                data-product_sku="Kozo-Sushi-Platter"
                                                                                aria-label="Add to cart: &ldquo;Kozo Sushi Platter&rdquo;"
                                                                                rel="nofollow"><i
                                                                                    className="far fa-cart-plus"></i></a></div>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h3 className="box-title el-title"><a
                                                                                href="product/kozo-sushi-platter/index.html">Kozo
                                                                                Sushi Platter</a></h3>
                                                                            <div className="box-content">
                                                                                <p className="box-text">The registration fee covers
                                                                                    access</p>
                                                                                <div className="box-line"></div>
                                                                                <h4 className="box-price el-price"><span
                                                                                    className="woocommerce-Price-amount amount"><bdi><span
                                                                                        className="woocommerce-Price-currencySymbol">&#8377;</span>20</bdi></span>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="menu-list">
                                                                        <div className="box-img"><img loading="lazy" decoding="async"
                                                                            width="100" height="100"
                                                                            src="assets/images/product/product_1_9-100x100.png"
                                                                            className="attachment-restar-shop-thumb size-restar-shop-thumb wp-post-image"
                                                                            alt=""
                                                                            sizes="(max-width: 100px) 100vw, 100px" />
                                                                            <div className="actions"><a
                                                                                href="index0a67.html?add-to-cart=168"
                                                                                data-quantity="1"
                                                                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                data-product_id="168"
                                                                                data-product_sku="Chicken-Leg-Piece"
                                                                                aria-label="Add to cart: &ldquo;Chicken Leg Piece&rdquo;"
                                                                                rel="nofollow"><i
                                                                                    className="far fa-cart-plus"></i></a></div>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h3 className="box-title el-title"><a
                                                                                href="product/chicken-leg-piece/index.html">Chicken
                                                                                Leg Piece</a></h3>
                                                                            <div className="box-content">
                                                                                <p className="box-text">The registration fee covers
                                                                                    access</p>
                                                                                <div className="box-line"></div>
                                                                                <h4 className="box-price el-price"> <ins><span
                                                                                    className="woocommerce-Price-amount amount"><bdi><span
                                                                                        className="woocommerce-Price-currencySymbol">&#8377;</span>25</bdi></span></ins>
                                                                                    <del><span
                                                                                        className="woocommerce-Price-amount amount"><bdi><span
                                                                                            className="woocommerce-Price-currencySymbol">&#8377;</span>30</bdi></span></del>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="menu-list">
                                                                        <div className="box-img"><img loading="lazy" decoding="async"
                                                                            width="100" height="100"
                                                                            src="assets/images/product/product_1_8-100x100.png"
                                                                            className="attachment-restar-shop-thumb size-restar-shop-thumb wp-post-image"
                                                                            alt=""
                                                                            sizes="(max-width: 100px) 100vw, 100px" />
                                                                            <div className="actions"><a
                                                                                href="index8c8a.html?add-to-cart=167"
                                                                                data-quantity="1"
                                                                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                data-product_id="167"
                                                                                data-product_sku="Chicken-with-Rice"
                                                                                aria-label="Add to cart: &ldquo;Chicken with Rice&rdquo;"
                                                                                rel="nofollow"><i
                                                                                    className="far fa-cart-plus"></i></a></div>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h3 className="box-title el-title"><a
                                                                                href="product/chicken-with-rice/index.html">Chicken
                                                                                with Rice</a></h3>
                                                                            <div className="box-content">
                                                                                <p className="box-text">The registration fee covers
                                                                                    access</p>
                                                                                <div className="box-line"></div>
                                                                                <h4 className="box-price el-price"><span
                                                                                    className="woocommerce-Price-amount amount"><bdi><span
                                                                                        className="woocommerce-Price-currencySymbol">&#8377;</span>30</bdi></span>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="menu-list">
                                                                        <div className="box-img"><img loading="lazy" decoding="async"
                                                                            width="100" height="100"
                                                                            src="assets/images/product/product_1_4-100x100.png"
                                                                            className="attachment-restar-shop-thumb size-restar-shop-thumb wp-post-image"
                                                                            alt=""
                                                                            sizes="(max-width: 100px) 100vw, 100px" />
                                                                            <div className="actions"><a
                                                                                href="indexedca.html?add-to-cart=164"
                                                                                data-quantity="1"
                                                                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                data-product_id="164"
                                                                                data-product_sku="Raw-Salmon-Salad"
                                                                                aria-label="Add to cart: &ldquo;Raw Salmon Salad&rdquo;"
                                                                                rel="nofollow"><i
                                                                                    className="far fa-cart-plus"></i></a></div>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h3 className="box-title el-title"><a
                                                                                href="product/raw-salmon-salad/index.html">Raw
                                                                                Salmon Salad</a></h3>
                                                                            <div className="box-content">
                                                                                <p className="box-text">The registration fee covers
                                                                                    access</p>
                                                                                <div className="box-line"></div>
                                                                                <h4 className="box-price el-price"> <ins><span
                                                                                    className="woocommerce-Price-amount amount"><bdi><span
                                                                                        className="woocommerce-Price-currencySymbol">&#8377;</span>18</bdi></span></ins>
                                                                                    <del><span
                                                                                        className="woocommerce-Price-amount amount"><bdi><span
                                                                                            className="woocommerce-Price-currencySymbol">&#8377;</span>22</bdi></span></del>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="menu-list">
                                                                        <div className="box-img"><img loading="lazy" decoding="async"
                                                                            width="100" height="100"
                                                                            src="assets/images/product/product_1_3-100x100.png"
                                                                            className="attachment-restar-shop-thumb size-restar-shop-thumb wp-post-image"
                                                                            alt=""
                                                                            sizes="(max-width: 100px) 100vw, 100px" />
                                                                            <div className="actions"><a
                                                                                href="indexfdfc.html?add-to-cart=163"
                                                                                data-quantity="1"
                                                                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                data-product_id="163"
                                                                                data-product_sku="Salmon-Salad"
                                                                                aria-label="Add to cart: &ldquo;Salmon Salad&rdquo;"
                                                                                rel="nofollow"><i
                                                                                    className="far fa-cart-plus"></i></a></div>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h3 className="box-title el-title"><a
                                                                                href="product/salmon-salad/index.html">Salmon
                                                                                Salad</a></h3>
                                                                            <div className="box-content">
                                                                                <p className="box-text">The registration fee covers
                                                                                    access</p>
                                                                                <div className="box-line"></div>
                                                                                <h4 className="box-price el-price"> <ins><span
                                                                                    className="woocommerce-Price-amount amount"><bdi><span
                                                                                        className="woocommerce-Price-currencySymbol">&#8377;</span>22</bdi></span></ins>
                                                                                    <del><span
                                                                                        className="woocommerce-Price-amount amount"><bdi><span
                                                                                            className="woocommerce-Price-currencySymbol">&#8377;</span>33</bdi></span></del>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="menu-list">
                                                                        <div className="box-img"><img loading="lazy" decoding="async"
                                                                            width="100" height="100"
                                                                            src="assets/images/product/product_1_5-100x100.png"
                                                                            className="attachment-restar-shop-thumb size-restar-shop-thumb wp-post-image"
                                                                            alt=""
                                                                            sizes="(max-width: 100px) 100vw, 100px" />
                                                                            <div className="actions"><a
                                                                                href="index7962.html?add-to-cart=148"
                                                                                data-quantity="1"
                                                                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                                                                data-product_id="148"
                                                                                data-product_sku="Salmon-Beef-Stack"
                                                                                aria-label="Add to cart: &ldquo;Salmon Beef Stack&rdquo;"
                                                                                rel="nofollow"><i
                                                                                    className="far fa-cart-plus"></i></a></div>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h3 className="box-title el-title"><a
                                                                                href="product/salmon-beef-stack/index.html">Salmon
                                                                                Beef Stack</a></h3>
                                                                            <div className="box-content">
                                                                                <p className="box-text">The registration fee covers
                                                                                    access</p>
                                                                                <div className="box-line"></div>
                                                                                <h4 className="box-price el-price"> <ins><span
                                                                                    className="woocommerce-Price-amount amount"><bdi><span
                                                                                        className="woocommerce-Price-currencySymbol">&#8377;</span>20</bdi></span></ins>
                                                                                    <del><span
                                                                                        className="woocommerce-Price-amount amount"><bdi><span
                                                                                            className="woocommerce-Price-currencySymbol">&#8377;</span>25</bdi></span></del>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="elementor-element elementor-element-c851ad9 e-flex e-con-boxed e-con e-parent main1"
                                        data-id="c851ad9" data-element_type="container">
                                        <div className="e-con-inner">
                                            <div className="elementor-element elementor-element-c682fa7 e-con-full e-flex e-con e-child"
                                                data-id="c682fa7" data-element_type="container">
                                                <div className="elementor-element elementor-element-010d141 elementor-widget elementor-widget-restaroffer"
                                                    data-id="010d141" data-element_type="widget"
                                                    data-widget_type="restaroffer.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="row offer-box-row">
                                                            <div className="col-xl-4 col-md-6">
                                                                <div className="offer-box mega-hover cardimg1">
                                                                    <Zoom>
                                                                        <h3 className="box-title title-ani2 ">BURGER</h3>
                                                                        <p className="box-text title-ani2">Get a 20% Discount On This Week
                                                                        </p><a className="line-btn title-ani2"
                                                                            href="product/grilled-chicken/index.html">Order Now</a></Zoom>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 col-md-6">
                                                                <div className="offer-box mega-hover cardimg2"
                                                                    data-bg-src="assets/images/banner/cta_bg_42.jpg">
                                                                    <Zoom>
                                                                        <h3 className="box-title title-ani2">FRENCH FRY</h3>
                                                                        <p className="box-text title-ani2">Get a 25% Discount On This Week
                                                                        </p><a className="line-btn title-ani2"
                                                                            href="product/grilled-chicken/index.html">Order Now</a></Zoom>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4 col-md-6 ">
                                                                <div className="offer-box mega-hover cardimg3"
                                                                    data-bg-src="assets/images/banner/cta_bg_43.jpg">
                                                                    <Zoom>
                                                                        <h3 className="box-title title-ani2">ITALIAN PASTA</h3>
                                                                        <p className="box-text title-ani2">Get a 35% Discount On This Week
                                                                        </p><a className="line-btn title-ani2"
                                                                            href="product/grilled-chicken/index.html">Order Now</a></Zoom>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="elementor-element elementor-element-dcc8a02 e-flex e-con-boxed e-con e-parent main1"
                                        data-id="dcc8a02" data-element_type="container">
                                        <div className="e-con-inner">
                                            <div className="elementor-element elementor-element-72b862d e-con-full e-flex e-con e-child"
                                                data-id="72b862d" data-element_type="container">
                                                <div className="elementor-element elementor-element-9368247 elementor-widget elementor-widget-restarbrandlogo"
                                                    data-id="9368247" data-element_type="widget"
                                                    data-widget_type="restarbrandlogo.default">
                                                    <div className="elementor-widget-container">
                                                        <div className="swiper th-slider" id="brandSlider2"
                                                            data-slider-options='{"spaceBetween":45,"breakpoints":{"0":{"slidesPerView":2},"576":{"slidesPerView":"2"},"768":{"slidesPerView":"3"},"992":{"slidesPerView":"4"},"1200":{"slidesPerView":"4"},"1300":{"slidesPerView":"5"},"1500":{"slidesPerView":"5"}}}'>
                                                            <Slider {...settings4}>

                                                                <div className="swiper-slide">
                                                                    <div className="brand-box style2">
                                                                        <img decoding="async"
                                                                            src="assets/images/brand_2_1.svg"
                                                                            alt="brand_2_1" /></div>
                                                                </div>
                                                                <div className="swiper-slide">
                                                                    <div className="brand-box style2"><img decoding="async"
                                                                        src="assets/images/brand_2_2.svg"
                                                                        alt="brand_2_2" /></div>
                                                                </div>
                                                                <div className="swiper-slide">
                                                                    <div className="brand-box style2"><img decoding="async"
                                                                        src="assets/images/brand_2_3.svg"
                                                                        alt="brand_2_3" /></div>
                                                                </div>
                                                                <div className="swiper-slide">
                                                                    <div className="brand-box style2"><img decoding="async"
                                                                        src="assets/images/brand_2_4.svg"
                                                                        alt="brand_2_4" /></div>
                                                                </div>
                                                                <div className="swiper-slide">
                                                                    <div className="brand-box style2"><img decoding="async"
                                                                        src="assets/images/brand_2_5.svg"
                                                                        alt="brand_2_5" /></div>
                                                                </div>

                                                            </Slider>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-element elementor-element-3dcb18e e-con-full bg-white e-flex e-con e-parent"
                                        data-id="3dcb18e"
                                        data-element_type="container"
                                        data-settings='{"background_background":"classic"}'
                                    >
                                        <div
                                            className="elementor-element elementor-element-927b0c1 elementor-widget elementor-widget-restarshapeimage"
                                            data-id="927b0c1"
                                            data-element_type="widget"
                                            data-widget_type="restarshapeimage.default"
                                        >
                                            <div className="elementor-widget-container">
                                                {/* Image */}
                                                <div
                                                    className="shape-mockup  d-none d-xl-block"
                                                    data-top="7%"
                                                    data-right="0.01%"
                                                >
                                                    <img
                                                        decoding="async"
                                                        src="assets/images/tomato_1.png"
                                                        data-speed="1.1"
                                                        alt="Shape Image"
                                                    />
                                                </div>
                                                {/* End Image */}
                                            </div>
                                        </div>
                                        <div
                                            className="elementor-element elementor-element-9191c47 elementor-widget elementor-widget-restarshapeimage"
                                            data-id="9191c47"
                                            data-element_type="widget"
                                            data-widget_type="restarshapeimage.default"
                                        >
                                            <div className="elementor-widget-container">

                                                <div
                                                    className="shape-mockup  moving d-none d-xl-block"
                                                    data-bottom="0.01%"
                                                    data-right="0.01%"
                                                >
                                                    <img
                                                        decoding="async"
                                                        src="assets/images/red_cilli.png"
                                                        data-speed="1.12"
                                                        alt="Shape Image"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="elementor-element elementor-element-9750352 e-flex e-con-boxed e-con e-child"
                                            data-id={9750352}
                                            data-element_type="container"
                                        >
                                            <div className="e-con-inner">
                                                <div
                                                    className="elementor-element elementor-element-ab9d4b5 e-con-full e-flex e-con e-child"
                                                    data-id="ab9d4b5"
                                                    data-element_type="container"
                                                >
                                                    <div
                                                        className="elementor-element elementor-element-a15e50f e-con-full e-flex e-con e-child"
                                                        data-id="a15e50f"
                                                        data-element_type="container"
                                                    >
                                                        <div
                                                            className="elementor-element elementor-element-5810a41 elementor-widget elementor-widget-restarimage"
                                                            data-id="5810a41"
                                                            data-element_type="widget"
                                                            data-widget_type="restarimage.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="reserve-img1">
                                                                    <img
                                                                        decoding="async"
                                                                        src="vendor/uploads/2024/05/booking_1.jpg"
                                                                        alt="booking_1"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-element elementor-element-8831836 e-con-full  text-center text-xl-start ps-xxl-5 e-flex e-con e-child"
                                                        data-id={8831836}
                                                        data-element_type="container"
                                                    >
                                                        <div
                                                            className="elementor-element elementor-element-d204763 elementor-widget elementor-widget-text-editor"
                                                            data-id="d204763"
                                                            data-element_type="widget"
                                                            data-widget_type="text-editor.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="title-area mb-30">
                                                                    <Fade direction='up'>  <span className="sub-title4">Need Booking?</span></Fade>
                                                                    <Fade direction='up'>       <h2 className="sec-title">Reserve Your Personal Table Now!</h2></Fade>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-fcabedd elementor-widget elementor-widget-restarcontactform"
                                                            data-id="fcabedd"
                                                            data-element_type="widget"
                                                            data-widget_type="restarcontactform.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="th-form-area">
                                                                    <div
                                                                        className="wpcf7 no-js"
                                                                        id="wpcf7-f1215-p148-o1"
                                                                        lang="en-US"
                                                                        dir="ltr"
                                                                    >
                                                                        <div className="screen-reader-response">
                                                                            <p role="status" aria-live="polite" aria-atomic="true"></p>
                                                                            <ul />
                                                                        </div>
                                                                        <form
                                                                            action="https://wordpress.themeholy.com/restar/#wpcf7-f1215-p148-o1"
                                                                            method="post"
                                                                            className="wpcf7-form init"
                                                                            aria-label="Contact form"
                                                                            noValidate="novalidate"
                                                                            data-status="init"
                                                                        >
                                                                            <div style={{ display: "none" }}>
                                                                                <input type="hidden" name="_wpcf7" defaultValue={1215} />
                                                                                <input
                                                                                    type="hidden"
                                                                                    name="_wpcf7_version"
                                                                                    defaultValue="5.9.5"
                                                                                />
                                                                                <input
                                                                                    type="hidden"
                                                                                    name="_wpcf7_locale"
                                                                                    defaultValue="en_US"
                                                                                />
                                                                                <input
                                                                                    type="hidden"
                                                                                    name="_wpcf7_unit_tag"
                                                                                    defaultValue="wpcf7-f1215-p148-o1"
                                                                                />
                                                                                <input
                                                                                    type="hidden"
                                                                                    name="_wpcf7_container_post"
                                                                                    defaultValue={148}
                                                                                />
                                                                                <input
                                                                                    type="hidden"
                                                                                    name="_wpcf7_posted_data_hash"
                                                                                    defaultValue=""
                                                                                />
                                                                            </div>
                                                                            <div className="reservation-form3">
                                                                                <div className="row">
                                                                                    <div className="form-group col-12">
                                                                                        <span
                                                                                            className="wpcf7-form-control-wrap"
                                                                                            data-name="phone-number"
                                                                                        >
                                                                                            <input
                                                                                                size={40}
                                                                                                className="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel form-control"
                                                                                                aria-required="true"
                                                                                                aria-invalid="false"
                                                                                                placeholder="Phone Number"
                                                                                                defaultValue=""
                                                                                                type="tel"
                                                                                                name="phone-number"
                                                                                            />
                                                                                        </span>
                                                                                        <i className="fal fa-phone" />
                                                                                    </div>
                                                                                    <div className="form-group col-12">
                                                                                        <span
                                                                                            className="wpcf7-form-control-wrap"
                                                                                            data-name="date-time"
                                                                                        >
                                                                                            <input
                                                                                                size={40}
                                                                                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control date-time-pick"
                                                                                                aria-required="true"
                                                                                                aria-invalid="false"
                                                                                                placeholder="Date & Time"
                                                                                                defaultValue=""
                                                                                                type="text"
                                                                                                name="date-time"
                                                                                            />
                                                                                        </span>
                                                                                        <i className="fal fa-calendar-alt" />
                                                                                    </div>
                                                                                    <div className="form-group col-12">
                                                                                        <span
                                                                                            className="wpcf7-form-control-wrap"
                                                                                            data-name="people"
                                                                                        >
                                                                                            <input
                                                                                                size={40}
                                                                                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                                                                                                aria-required="true"
                                                                                                aria-invalid="false"
                                                                                                placeholder="Total Person"
                                                                                                defaultValue=""
                                                                                                type="text"
                                                                                                name="people"
                                                                                            />
                                                                                        </span>
                                                                                        <i className="fal fa-user-group" />
                                                                                    </div>
                                                                                </div>
                                                                                <button className="th-btn wpcf7">Reservation Now</button>
                                                                            </div>
                                                                            <div
                                                                                className="wpcf7-response-output"
                                                                                aria-hidden="true"
                                                                            ></div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div
                                        className="elementor-element elementor-element-49de914 e-flex e-con-boxed e-con e-parent"
                                        data-id="49de914"
                                        data-element_type="container"
                                    >
                                        <div className="e-con-inner">
                                            <div
                                                className="elementor-element elementor-element-20f615f e-con-full e-flex e-con e-child"
                                                data-id="20f615f"
                                                data-element_type="container"
                                            >
                                                <div
                                                    className="elementor-element elementor-element-0c6e14c elementor-widget elementor-widget-restarsectiontitle"
                                                    data-id="0c6e14c"
                                                    data-element_type="widget"
                                                    data-widget_type="restarsectiontitle.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="title-area text-center ">
                                                            <span className="sub-title3 ">
                                                                <img decoding="async" src="assets/images/chef.png" alt="Shape" />
                                                                NEW &amp; BLOG
                                                            </span>
                                                            <h2 className="sec-title  ">Our Latest News &amp; Blog</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-element elementor-element-5aa550e elementor-widget elementor-widget-restarblog"
                                                    data-id="5aa550e"
                                                    data-element_type="widget"
                                                    data-widget_type="restarblog.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="slider-area">
                                                            <div
                                                                className="swiper th-slider has-shadow"
                                                                id="blogSlider1"
                                                                data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":1},"768":{"slidesPerView":2},"992":{"slidesPerView":2},"1200":{"slidesPerView":3},"1300":{"slidesPerView":3}}}'
                                                            >
                                                                <Slider {...settings3}>
                                                                    <div className="swiper-slide">
                                                                        <div className="blog-box">
                                                                            <div className="blog-img">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={410}
                                                                                    height={300}
                                                                                    src="vendor/uploads/2024/04/blog-s-1-7-410x300.jpg"
                                                                                    className="attachment-restar_410X300 size-restar_410X300 wp-post-image"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="blog-content">
                                                                                <div className="blog-meta">
                                                                                    <a href="2024/04/28/index.html">April 28, 2024</a>
                                                                                    <a href="category/burger/index.html">Burger</a>
                                                                                </div>
                                                                                <h3 className="box-title">
                                                                                    <a href="good-cook-always-use-these-types-of-tricks/index.html">
                                                                                        Good Cook Always Use These Types of Tricks
                                                                                    </a>
                                                                                </h3>
                                                                                <a
                                                                                    href="good-cook-always-use-these-types-of-tricks/index.html"
                                                                                    className="th-btn btn-sm style-border"
                                                                                >
                                                                                    Read More
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="blog-box">
                                                                            <div className="blog-img">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={410}
                                                                                    height={300}
                                                                                    src="vendor/uploads/2024/04/blog-s-1-3-410x300.jpg"
                                                                                    className="attachment-restar_410X300 size-restar_410X300 wp-post-image"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="blog-content">
                                                                                <div className="blog-meta">
                                                                                    <a href="2024/04/28/index.html">April 28, 2024</a>
                                                                                    <a href="category/lunch/index.html">Lunch</a>
                                                                                </div>
                                                                                <h3 className="box-title">
                                                                                    <a href="what-types-of-massala-is-the-best-in-cooking/index.html">
                                                                                        What Types of Massala Is The Best in Cooking
                                                                                    </a>
                                                                                </h3>
                                                                                <a
                                                                                    href="what-types-of-massala-is-the-best-in-cooking/index.html"
                                                                                    className="th-btn btn-sm style-border"
                                                                                >
                                                                                    Read More
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="blog-box">
                                                                            <div className="blog-img">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={410}
                                                                                    height={300}
                                                                                    src="vendor/uploads/2024/04/blog-s-1-4-410x300.jpg"
                                                                                    className="attachment-restar_410X300 size-restar_410X300 wp-post-image"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="blog-content">
                                                                                <div className="blog-meta">
                                                                                    <a href="2024/04/28/index.html">April 28, 2024</a>
                                                                                    <a href="category/fast-foods/index.html">Fast Foods</a>
                                                                                </div>
                                                                                <h3 className="box-title">
                                                                                    <a href="only-pure-ingredients-can-increase-food-taste/index.html">
                                                                                        Only Pure Ingredients Can Increase Food Taste
                                                                                    </a>
                                                                                </h3>
                                                                                <a
                                                                                    href="only-pure-ingredients-can-increase-food-taste/index.html"
                                                                                    className="th-btn btn-sm style-border"
                                                                                >
                                                                                    Read More
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="blog-box">
                                                                            <div className="blog-img">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={410}
                                                                                    height={300}
                                                                                    src="vendor/uploads/2024/04/blog-s-1-1-410x300.jpg"
                                                                                    className="attachment-restar_410X300 size-restar_410X300 wp-post-image"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="blog-content">
                                                                                <div className="blog-meta">
                                                                                    <a href="2024/04/28/index.html">April 28, 2024</a>
                                                                                    <a href="category/restaurant/index.html">Restaurant</a>
                                                                                </div>
                                                                                <h3 className="box-title">
                                                                                    <a href="the-fresh-ingredients-that-define-our-dishes/index.html">
                                                                                        The Fresh Ingredients That Define Our Dishes
                                                                                    </a>
                                                                                </h3>
                                                                                <a
                                                                                    href="the-fresh-ingredients-that-define-our-dishes/index.html"
                                                                                    className="th-btn btn-sm style-border"
                                                                                >
                                                                                    Read More
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="blog-box">
                                                                            <div className="blog-img">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={410}
                                                                                    height={300}
                                                                                    src="vendor/uploads/2024/04/blog-s-1-6-410x300.jpg"
                                                                                    className="attachment-restar_410X300 size-restar_410X300 wp-post-image"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="blog-content">
                                                                                <div className="blog-meta">
                                                                                    <a href="2024/04/28/index.html">April 28, 2024</a>
                                                                                    <a href="category/dinner/index.html">Dinner</a>
                                                                                </div>
                                                                                <h3 className="box-title">
                                                                                    <a href="stories-behind-our-most-loved-recipes-foodies/index.html">
                                                                                        Stories Behind Our Most Loved Recipes Foodies
                                                                                    </a>
                                                                                </h3>
                                                                                <a
                                                                                    href="stories-behind-our-most-loved-recipes-foodies/index.html"
                                                                                    className="th-btn btn-sm style-border"
                                                                                >
                                                                                    Read More
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="swiper-slide">
                                                                        <div className="blog-box">
                                                                            <div className="blog-img">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={410}
                                                                                    height={300}
                                                                                    src="vendor/uploads/2024/04/blog-s-1-5-410x300.jpg"
                                                                                    className="attachment-restar_410X300 size-restar_410X300 wp-post-image"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="blog-content">
                                                                                <div className="blog-meta">
                                                                                    <a href="2024/04/28/index.html">April 28, 2024</a>
                                                                                    <a href="category/breakfast/index.html">Breakfast</a>
                                                                                </div>
                                                                                <h3 className="box-title">
                                                                                    <a href="classy-sauces-to-make-meat-melt-in-your-mouth/index.html">
                                                                                        Classy Sauces To Make Meat Melt In Your Mouth
                                                                                    </a>
                                                                                </h3>
                                                                                <a
                                                                                    href="classy-sauces-to-make-meat-melt-in-your-mouth/index.html"
                                                                                    className="th-btn btn-sm style-border"
                                                                                >
                                                                                    Read More
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Slider>
                                                            </div>

                                                            <button
                                                                data-slider-prev="#blogSlider1"
                                                                className="slider-arrow slider-prev"
                                                            >
                                                                <span className="icon" />
                                                            </button>
                                                            <button
                                                                data-slider-next="#blogSlider1"
                                                                className="slider-arrow slider-next"
                                                            >
                                                                <span className="icon" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>

                        {/* <!-- Smoother --> */}
                    </div>
                </div>
                {/* <!-- Smoother --> */}
                <div className="scroll-top">
                    <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98">
                        </path>
                    </svg>
                </div>
            </div>
        </>
    )
}
