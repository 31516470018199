import React from 'react'
import {Fade, Zoom ,Slide } from "react-awesome-reveal";
import CountUp from 'react-countup';
const Aboutus = () => {
  return (
    <>
<div id="smooth-wrapper">
  <div id="smooth-content">
  
    <div className="breadcumb-wrapper " id="breadcumbwrap">
      <div className="container">
        <div className="breadcumb-content">
          <h1 className="breadcumb-title">About Us</h1>
          <ul className="breadcumb-menu">
            <li>
              <a href="https://wordpress.themeholy.com/restar">Home</a>
            </li>
            <li>About Us</li>
          </ul>
        </div>
      </div>
    </div>
  
    <div className="restar-fluid">
      <div className="builder-page-wrapper">
        <div
          data-elementor-type="wp-page"
          data-elementor-id={52}
          className="elementor elementor-52"
        >
          <div
            className="elementor-element elementor-element-18234db e-flex e-con-boxed e-con e-parent"
            data-id="18234db"
            data-element_type="container"
          >
            <div className="e-con-inner">
              <div
                className="elementor-element elementor-element-be3da91 e-con-full e-flex e-con e-child"
                data-id="be3da91"
                data-element_type="container"
              >
                <div
                  className="elementor-element elementor-element-40be8bc elementor-widget elementor-widget-restarsectiontitle"
                  data-id="40be8bc"
                  data-element_type="widget"
                  data-widget_type="restarsectiontitle.default"
                >
                  <div className="elementor-widget-container">
                    <div className="title-area text-center ">
                      <span className="sub-title3 ">
                        <img
                          decoding="async"
                          src="assets/images/chef.png"
                          alt="title_icon2"
                        />
              <Fade direction='up' > OUR RESTAURANT SERVICES</Fade> 
                      </span>
                      <Fade direction='up' >   <h2 className="sec-title  ">
                        Our Commitment to Excellence
                      </h2></Fade>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-220a078 elementor-widget elementor-widget-restarservice"
                  data-id="220a078"
                  data-element_type="widget"
                  data-widget_type="restarservice.default"
                >
                  <div className="elementor-widget-container">
                    <div className="row gy-4">
                      <div className="col-xl-3 col-md-6">
                        <div className="feature-box">
                          <div className="box-shape">
                            <img
                              decoding="async"
                              src=" vendor/uploads/2024/05/flower_7.png"
                              alt="flower_7"
                            />
                          </div>
                          <div className="box-icon">
                            <img
                              decoding="async"
                              src="assets/images/discount.png"
                              alt="feature_box_1"
                            />
                          </div>
                          <div className="media-body">
                            <h3 className="box-title text-ani">
                              <a href=" service-details/index.html">
                                Discount Voucher
                              </a>
                            </h3>
                            <div className="text-ani">
                              <p className="box-text">
                                Indulge in a gourmet journey with a menu Our
                                chefs use the finest ingredients.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-6">
                        <div className="feature-box">
                          <div className="box-shape">
                            <img
                              decoding="async"
                              src=" vendor/uploads/2024/05/flower_7.png"
                              alt="flower_7"
                            />
                          </div>
                          <div className="box-icon">
                            <img
                              decoding="async"
                              src="assets/images/recipe.png"
                              alt="feature_box_2"
                            />
                          </div>
                          <div className="media-body">
                            <h3 className="box-title text-ani">
                              <a href=" service-details/index.html">
                                Original Recipes
                              </a>
                            </h3>
                            <div className="text-ani">
                              <p className="box-text">
                                Indulge in a gourmet journey with a menu Our
                                chefs use the finest ingredients.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-6">
                        <div className="feature-box">
                          <div className="box-shape">
                            <img
                              decoding="async"
                              src=" vendor/uploads/2024/05/flower_7.png"
                              alt="flower_7"
                            />
                          </div>
                          <div className="box-icon">
                            <img
                              decoding="async"
                              src="assets/images/burger.png"
                              alt="feature_box_3"
                            />
                          </div>
                          <div className="media-body">
                            <h3 className="box-title text-ani">
                              <a href=" service-details/index.html">
                                100% Fresh Foods
                              </a>
                            </h3>
                            <div className="text-ani">
                              <p className="box-text">
                                Indulge in a gourmet journey with a menu Our
                                chefs use the finest ingredients.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-md-6">
                        <div className="feature-box">
                          <div className="box-shape">
                            <img
                              decoding="async"
                              src=" vendor/uploads/2024/05/flower_7.png"
                              alt="flower_7"
                            />
                          </div>
                          <div className="box-icon">
                            <img
                              decoding="async"
                              src="assets/images/table-etiquette.png"
                              alt="feature_box_4"
                            />
                          </div>
                          <div className="media-body">
                            <h3 className="box-title text-ani">
                              <a href=" service-details/index.html">
                                Serve On Table
                              </a>
                            </h3>
                            <div className="text-ani">
                              <p className="box-text">
                                Indulge in a gourmet journey with a menu Our
                                chefs use the finest ingredients.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-6103973 e-con-full e-flex e-con e-parent"
            data-id={6103973}
            data-element_type="container"
          >
            <div
              className="elementor-element elementor-element-7e67942 elementor-widget elementor-widget-restarshapeimage"
              data-id="7e67942"
              data-element_type="widget"
              data-widget_type="restarshapeimage.default"
            >
              <div className="elementor-widget-container">
                {/* Image */}
                <div
                  className="shape-mockup  jump "
                  data-top="10%"
                  data-right="0.01%"
                >
                  <img
                    decoding="async"
                    src=" vendor/uploads/2024/05/flower_2.png"
                    data-speed="1.1"
                    alt="Shape Image"
                  />
                </div>
                {/* End Image */}
              </div>
            </div>
            <div
              className="elementor-element elementor-element-246f327 elementor-widget elementor-widget-restarshapeimage"
              data-id="246f327"
              data-element_type="widget"
              data-widget_type="restarshapeimage.default"
            >
              <div className="elementor-widget-container">
                {/* Image */}
                <div
                  className="shape-mockup  jump "
                  data-bottom="10%"
                  data-right="8%"
                >
                  <img
                    decoding="async"
                    src=" vendor/uploads/2024/05/garlic_1.png"
                    data-speed="1.12"
                    alt="Shape Image"
                  />
                </div>
                {/* End Image */}
              </div>
            </div>
            <div
              className="elementor-element elementor-element-96aa7d9 e-flex e-con-boxed e-con e-child"
              data-id="96aa7d9"
              data-element_type="container"
            >
              <div className="e-con-inner">
                <div
                  className="elementor-element elementor-element-050c6f4 e-con-full e-flex e-con e-child"
                  data-id="050c6f4"
                  data-element_type="container"
                >
                  <div
                    className="elementor-element elementor-element-685a62b e-con-full e-flex e-con e-child"
                    data-id="685a62b"
                    data-element_type="container"
                  >
                    <div
                      className="elementor-element elementor-element-010672d elementor-widget elementor-widget-restarimage"
                      data-id="010672d"
                      data-element_type="widget"
                      data-widget_type="restarimage.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="img-box8">
                          <div className="shape1">
                            <img
                              decoding="async"
                              src=" vendor/uploads/2024/05/flower_8.png"
                              alt="flower_8"
                              className="gspin"
                            />
                          </div>
                          <div className="img1">
                            <img
                              decoding="async"
                              src=" vendor/uploads/2024/05/about_5_1.jpg"
                              alt="about_5_1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-5cbd3c6 e-con-full ps-xxl-5 e-flex e-con e-child"
                    data-id="5cbd3c6"
                    data-element_type="container"
                  >
                    <div
                      className="elementor-element elementor-element-1b288f8 elementor-widget elementor-widget-restarsectiontitle"
                      data-id="1b288f8"
                      data-element_type="widget"
                      data-widget_type="restarsectiontitle.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="title-area text-center mb-35">
                          <span className="sub-title3 ">
                            <img
                              decoding="async"
                              src="assets/images/chef.png"
                              alt="title_icon2"
                            />
                       <Slide direction="up">    ABOUT OUR RESTAURANT</Slide> 
                          </span>
                          <Slide direction="up">      <h2 className="sec-title  ">
                            A Gastronomic Haven for The Discerning Palate
                          </h2></Slide>
                          <div className="text-ani">
                          <Slide direction="up">          <p className="sec-text">
                              Indulge in a gourmet journey with menu that
                              showcases a fusion of flavors. Our chefs use the
                              finest ingredients to create dishes that are not
                              just meals but unforgettable experiences.
                            </p></Slide>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-63cfc8d elementor-widget elementor-widget-image"
                      data-id="63cfc8d"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <style
                          dangerouslySetInnerHTML={{
                            __html:
                              '\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.21.0 - 26-05-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\ttext-align: center\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image a {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image a img[src$=".svg"] {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 48px\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-image img {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tvertical-align: middle;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t'
                          }}
                        />{" "}
                        <img
                          decoding="async"
                          width={278}
                          height={8}
                          src=" vendor/uploads/2024/05/line_dotted_1.svg"
                          className="attachment-full size-full wp-image-420"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-ffd63bc elementor-widget elementor-widget-text-editor"
                      data-id="ffd63bc"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <style
                          dangerouslySetInnerHTML={{
                            __html:
                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.21.0 - 26-05-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbackground-color: #69727d;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: #fff\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: #69727d;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tborder: 3px solid;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tbackground-color: transparent\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-top: 8px\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 1em;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\theight: 1em\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tfloat: left;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\ttext-align: center;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: 1;\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 50px\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor .elementor-drop-cap-letter {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                          }}
                        />
                        <h4 className="box-title text-ani">JOSEFINE</h4>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-35e578a text-ani elementor-widget elementor-widget-image"
                      data-id="35e578a"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <img
                          decoding="async"
                          width={146}
                          height={80}
                          src=" vendor/uploads/2024/05/sign.png"
                          className="attachment-full size-full wp-image-380"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-1c39a2f e-flex e-con-boxed e-con e-parent"
            data-id="1c39a2f"
            data-element_type="container"
            data-settings='{"background_background":"classic"}'
          >
            <div className="e-con-inner">
              <div
                className="elementor-element elementor-element-40e950a e-con-full e-flex e-con e-child"
                data-id="40e950a"
                data-element_type="container"
              >
                <div
                  className="elementor-element elementor-element-c738fb3 elementor-widget elementor-widget-restarcounterup"
                  data-id="c738fb3"
                  data-element_type="widget"
                  data-widget_type="restarcounterup.default"
                >
                  <div className="elementor-widget-container">
                    <div className="counter-card-wrap ">
                      <div className="counter-card">
                        <div className="box-shadow">2.8k</div>
                        <h2 className="box-number num">
                        <CountUp className="counter-number" end={500 } duration={10} />k
                        </h2>
                        <p className="box-text desc">Our Dally Order</p>
                      </div>
                      <div className="counter-card">
                        <div className="box-shadow">28</div>
                        <h2 className="box-number num">
                        <CountUp className="counter-number" end={100} duration={10} />   
                        </h2>
                        <p className="box-text desc">Specialist Chef</p>
                      </div>
                      <div className="counter-card">
                        <div className="box-shadow">139</div>
                        <h2 className="box-number num">
                        <CountUp className="counter-number" end={60} duration={10} /> 
                        </h2>
                        <p className="box-text desc">Our Menu &amp; Dish</p>
                      </div>
                      <div className="counter-card">
                        <div className="box-shadow">99</div>
                        <h2 className="box-number num">
                        <CountUp className="counter-number" end={170} duration={10} /> 
                        </h2>
                        <p className="box-text desc">Our Members</p>
                      </div>
                      <div className="counter-card">
                        <div className="box-shadow">39</div>
                        <h2 className="box-number num">
                        <CountUp className="counter-number" end={190} duration={10} /> 
                        </h2>
                        <p className="box-text desc">Won the Awards</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-398697c e-flex e-con-boxed e-con e-parent"
            data-id="398697c"
            data-element_type="container"
          >
            <div className="e-con-inner">
              <div
                className="elementor-element elementor-element-49db3e4 e-con-full e-flex e-con e-child"
                data-id="49db3e4"
                data-element_type="container"
              >
                <div
                  className="elementor-element elementor-element-a910555 elementor-widget elementor-widget-restarvideo"
                  data-id="a910555"
                  data-element_type="widget"
                  data-widget_type="restarvideo.default"
                >
                  <div className="elementor-widget-container">
                    <div
                      className="th-video video-box1 "
                      data-pos-for="#story-sec"
                      data-sec-pos="bottom-half"
                    >
                 <Fade direction='up'>    <img
                        decoding="async"
                        src=" vendor/uploads/2024/05/video_1.jpg"
                        alt="video_1"
                      /></Fade> 
                      <a
                        href="https://www.youtube.com/watch?v=_sI_Ps7JSEk"
                        className="play-btn style2 popup-video"
                      >
                        <i className="fa-sharp fa-solid fa-play" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-3a6af5c e-flex e-con-boxed e-con e-parent"
            data-id="3a6af5c"
            data-element_type="container"
            id="story-sec"
            data-settings='{"background_background":"classic"}'
          >
            <div className="e-con-inner">
              <div
                className="elementor-element elementor-element-2972ec8 e-con-full e-flex e-con e-child"
                data-id="2972ec8"
                data-element_type="container"
              >
                <div
                  className="elementor-element elementor-element-e8e4d7f elementor-widget elementor-widget-restarsectiontitle"
                  data-id="e8e4d7f"
                  data-element_type="widget"
                  data-widget_type="restarsectiontitle.default"
                >
                  <div className="elementor-widget-container">
                    <div className="title-area text-center ">
                      <span className="sub-title3 ">
                        <img
                          decoding="async"
                          src="assets/images/chef.png"
                          alt="title_icon2"
                        />
                   <Slide direction="up">         ABOUT STORY </Slide>
                      </span>
                      <Slide direction="up">      <h2 className="sec-title  ">About Our Journey Story</h2></Slide>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-8092c68 elementor-widget elementor-widget-restarstory"
                  data-id="8092c68"
                  data-element_type="widget"
                  data-widget_type="restarstory.default"
                >
                  <div className="elementor-widget-container">
                    <div className="row story-box-row">
                      <div className="col-lg-3 col-sm-6">
                        <div className="story-box ">
                          <div
                            className="box-img"
                            data-mask-src="https://wordpress.themeholy.com/restar/vendor/plugins/restar-core/assets/img/story_bg_mask.png"
                          >
                            <img
                              decoding="async"
                              src=" vendor/uploads/2024/05/story_1_1.jpg"
                              alt="story_1_1"
                            />
                          </div>
                          <div className="box-content text-ani">
                          <Slide direction="up">      <h3 className="box-title">January 2020</h3></Slide>
                          <Slide direction="up">     <p className="box-text">
                              Our restaurant is a haven for food enthusiasts
                              seeking an elevated dining experience.
                            </p></Slide>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <div className="story-box even">
                          <div
                            className="box-img"
                            data-mask-src="https://wordpress.themeholy.com/restar/vendor/plugins/restar-core/assets/img/story_bg_mask.png"
                          >
                            <img
                              decoding="async"
                              src=" vendor/uploads/2024/05/story_1_2.jpg"
                              alt="story_1_2"
                            />
                          </div>
                          <div className="box-content text-ani">
                          <Slide direction="up">       <h3 className="box-title">February 2021</h3></Slide>
                          <Slide direction="up">      <p className="box-text">
                              Our restaurant is a haven for food enthusiasts
                              seeking an elevated dining experience.
                            </p></Slide>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <div className="story-box ">
                          <div
                            className="box-img"
                            data-mask-src="https://wordpress.themeholy.com/restar/vendor/plugins/restar-core/assets/img/story_bg_mask.png"
                          >
                            <img
                              decoding="async"
                              src=" vendor/uploads/2024/05/story_1_3.jpg"
                              alt="story_1_3"
                            />
                          </div>
                          <div className="box-content text-ani">
                          <Slide direction="up">         <h3 className="box-title">March 2022</h3></Slide>
                          <Slide direction="up">       <p className="box-text">
                              Our restaurant is a haven for food enthusiasts
                              seeking an elevated dining experience.
                            </p></Slide>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6">
                        <div className="story-box even">
                          <div
                            className="box-img"
                            data-mask-src="https://wordpress.themeholy.com/restar/vendor/plugins/restar-core/assets/img/story_bg_mask.png"
                          >
                            <img
                              decoding="async"
                              src=" vendor/uploads/2024/05/story_1_4.jpg"
                              alt="story_1_4"
                            />
                          </div>
                          <div className="box-content text-ani">
                          <Slide direction="up">          <h3 className="box-title">April 2023</h3></Slide>
                          <Slide direction="up">       <p className="box-text">
                              Our restaurant is a haven for food enthusiasts
                              seeking an elevated dining experience.
                            </p></Slide>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-b0b19e6 e-con-full e-flex e-con e-parent"
            data-id="b0b19e6"
            data-element_type="container"
          >
            <div
              className="elementor-element elementor-element-afa73b9 e-flex e-con-boxed e-con e-child"
              data-id="afa73b9"
              data-element_type="container"
            >
              <div className="e-con-inner">
                <div
                  className="elementor-element elementor-element-6756bcf e-con-full e-flex e-con e-child"
                  data-id="6756bcf"
                  data-element_type="container"
                >
                  <div
                    className="elementor-element elementor-element-7ec35f6 e-con-full e-flex e-con e-child"
                    data-id="7ec35f6"
                    data-element_type="container"
                  >
                    <div
                      className="elementor-element elementor-element-1dd1bc0 elementor-widget elementor-widget-restarimage"
                      data-id="1dd1bc0"
                      data-element_type="widget"
                      data-widget_type="restarimage.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="img-box7">
                          <div className="shape1">
                            <img
                              decoding="async"
                              src=" vendor/uploads/2024/05/delivery_2.png"
                              alt="delivery_2"
                              
                            />
                          </div>
                          <div className="img1 ">
                    <Zoom >     <img
                              decoding="async"
                              src=" vendor/uploads/2024/05/delivery_1.png"
                              alt="delivery_1"
                            /></Zoom>   
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-1c34b0f e-con-full e-flex e-con e-child"
                    data-id="1c34b0f"
                    data-element_type="container"
                  >
                    <div
                      className="elementor-element elementor-element-cc84eb9 elementor-widget elementor-widget-restarsectiontitle"
                      data-id="cc84eb9"
                      data-element_type="widget"
                      data-widget_type="restarsectiontitle.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="title-area  ">
                          <span className="sub-title3 ">
                            <img
                              decoding="async"
                              src="assets/images/chef.png"
                              alt="title_icon2"
                            />
                    <Slide direction="up">            FOOD DELIVERY</Slide>
                          </span>
                          <Slide direction="up">       <h2 className="sec-title  ">
                            Where Good Food Meets Fast Delivery
                          </h2></Slide>
                          <div className="text-ani">
                          <Slide direction="up">           <p className="sec-text">
                              Indulge in a gourmet journey with a menu that
                              showcases a fusion of flavors. Our chefs use the
                              finest ingredients to create dishes that are not
                              just meals but unforgettable experiences.
                            </p></Slide>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-dea9681 elementor-widget elementor-widget-restarfeatures"
                      data-id="dea9681"
                      data-element_type="widget"
                      data-widget_type="restarfeatures.default"
                    >
                 <Slide direction="up">         <div className="elementor-widget-container">
                        <div className="check-feature-wrap">
                          <div className="check-feature text-ani">
                            <i className="fa-solid fa-badge-check" /> Delivery
                            In 30 Minutes
                          </div>
                          <div className="check-feature text-ani">
                            <i className="fa-solid fa-badge-check" /> Free
                            Shipping $50.00
                          </div>
                          <div className="check-feature text-ani">
                            <i className="fa-solid fa-badge-check" /> Delivery
                            On Doorstep
                          </div>
                          <div className="check-feature text-ani">
                            <i className="fa-solid fa-badge-check" /> On the Way
                            Tracing
                          </div>
                        </div>
                      </div></Slide>
                    </div>
                    <div
                      className="elementor-element elementor-element-76eb72c elementor-widget elementor-widget-restarbutton"
                      data-id="76eb72c"
                      data-element_type="widget"
                      data-widget_type="restarbutton.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="btn-wrapper">
                          <a className="th-btn th_btn " href=" shop/index.html">
                            Order Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 

  </div>
</div>

   
    </>
  )
}
export default Aboutus
