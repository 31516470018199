import React from 'react'

export default function Footer() {
  return (
   <>
   
   <div>
       <footer>
				<div data-elementor-type="wp-post" data-elementor-id="124" className="elementor elementor-124">
					<div className="elementor-element elementor-element-181eb8d footer-wrapper footer-layout1 e-con-full e-flex e-con e-parent"
						data-id="181eb8d" data-element_type="container"
						data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div className="elementor-element elementor-element-3c1be6a e-flex e-con-boxed e-con e-child"
							data-id="3c1be6a" data-element_type="container">
							<div className="e-con-inner">
								<div className="elementor-element elementor-element-a6be138 footer-widget elementor-widget-tablet__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-wp-widget-restar_aboutus_widget"
									data-id="a6be138" data-element_type="widget"
									data-widget_type="wp-widget-restar_aboutus_widget.default">
									<div className="elementor-widget-container">
										<div className="widget footer-widget">
											<div className="th-widget-about">
												<div className="about-logo"><a href="index.html"><img
															src="assets/images/logo1.png"
															alt="logo footer" /></a></div>
												<p className="about-text">We believe that great food shouldn't break the
													bank. That's why we offer affordable options without compromising on
													taste.</p>
												<div className="th-social  footer-social"><a href="https://facebook.com/"><i
															className="fab fa-facebook-f"></i></a><a
														href="https://twitter.com/"><i className="fab fa-twitter"></i></a><a
														href="https://instagram.com/"><i
															className="fab fa-instagram"></i></a><a
														href="https://linkedin.com/"><i
															className="fab fa-linkedin-in"></i></a></div>
											</div>
										</div>
									</div>
								</div>
								<div className="elementor-element elementor-element-f3d4d84 elementor-widget__width-auto elementor-widget-tablet__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-restarmenuselect"
									data-id="f3d4d84" data-element_type="widget"
									data-widget_type="restarmenuselect.default">
									<div className="elementor-widget-container">
										<div className="widget widget_nav_menu footer-widget">
											<h3 className="widget_title">Quick Links</h3>
											<div className="menu-all-pages-container">
												<ul id="menu-quick-link" className="menu style2">
													<li id="menu-item-133"
														className="menu-item menu-item-type-post_type menu-item-object-page menu-item-133">
														<a href="about/index.html">About Us</a>
													</li>
													<li id="menu-item-133"
														className="menu-item menu-item-type-post_type menu-item-object-page menu-item-133">
														<a href="about/index.html">Menus</a>
													</li>

													<li id="menu-item-135"
														className="menu-item menu-item-type-post_type menu-item-object-page menu-item-135">
														<a href="gallery/index.html">Our Gallery</a>
													</li>
													<li id="menu-item-135"
														className="menu-item menu-item-type-post_type menu-item-object-page menu-item-135">
														<a href="gallery/index.html">Contact</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div className="elementor-element elementor-element-cee7b48 elementor-widget__width-auto elementor-widget-tablet__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-restarmenuselect"
									data-id="cee7b48" data-element_type="widget"
									data-widget_type="restarmenuselect.default">
									<div className="elementor-widget-container">
										<div className="widget widget_nav_menu footer-widget">
											<h3 className="widget_title">Food Menu</h3>
											<div className="menu-all-pages-container">
												<ul id="menu-food-menu" className="menu style2">
													<li id="menu-item-1561"
														className="menu-item menu-item-type-post_type menu-item-object-product menu-item-1561">
														<a href="product/redish-oysters/index.html">Redish Oysters</a>
													</li>
													<li id="menu-item-1563"
														className="menu-item menu-item-type-post_type menu-item-object-product menu-item-1563">
														<a href="product/crispy-chicken/index.html">Crispy Chicken</a>
													</li>
													<li id="menu-item-1564"
														className="menu-item menu-item-type-post_type menu-item-object-product menu-item-1564">
														<a href="product/wild-mushroom/index.html">Wild Mushroom</a>
													</li>
													<li id="menu-item-1565"
														className="menu-item menu-item-type-post_type menu-item-object-product menu-item-1565">
														<a href="product/kozo-sushi-platter/index.html">Kozo Sushi
															Platter</a>
													</li>
													<li id="menu-item-1566"
														className="menu-item menu-item-type-post_type menu-item-object-product menu-item-1566">
														<a href="product/breakfast-platter/index.html">Breakfast
															Platter</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div className="elementor-element elementor-element-3f42e1f elementor-widget-tablet__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-restarcontactinfo"
									data-id="3f42e1f" data-element_type="widget"
									data-widget_type="restarcontactinfo.default">
									<div className="elementor-widget-container">
										<div className="widget footer-widget">
											<h3 className="widget_title">Get In Touch</h3>
											<div className="th-widget-contact">
												<div className="info-box">
													<h4 className="box-title title">Address Location</h4>
													<div className="box-content">
														<div className="box-icon">
															<i className="fas fa-location-dot"></i>
														</div>
														<p className="box-text desc">138 MacArthur Ave, USA</p>
													</div>
												</div>
												<div className="info-box">
													<h4 className="box-title title">Phone Number</h4>
													<div className="box-content">
														<div className="box-icon">
															<i className="fas fa-phone"></i>
														</div>
														<p className="box-text desc"><a
																href="tel:+(163)-2654-3564">+(163)-2654-3564</a></p>
													</div>
												</div>
												<div className="info-box">
													<h4 className="box-title title">Email address</h4>
													<div className="box-content">
														<div className="box-icon">
															<i className="fas fa-envelope"></i>
														</div>
														<p className="box-text desc"><a
																href="mailto:info@restar.com">info@restar.com</a></p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="elementor-element elementor-element-37f8df5 copyright-wrap e-flex e-con-boxed e-con e-child"
							data-id="37f8df5" data-element_type="container">
							<div className="e-con-inner">
								<div className="elementor-element elementor-element-1288521 elementor-widget elementor-widget-text-editor"
									data-id="1288521" data-element_type="widget" data-widget_type="text-editor.default">
									<div className="elementor-widget-container">
										<p className="copyright-text">Copyright <i className="fal fa-copyright"></i> 2024. All
											Rights Reserved.</p>
									</div>
								</div>
								<div className="elementor-element elementor-element-6099ecc elementor-widget elementor-widget-text-editor"
									data-id="6099ecc" data-element_type="widget" data-widget_type="text-editor.default">
									<div className="elementor-widget-container">
										<div className="footer-links">
											<ul>
												<li><a href="about/index.html">Terms &#038; Condition</a></li>
												<li><a href="about/index.html">Privacy &#038; Policy</a></li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
    </div>   
   </>
  )
}
