import React from 'react'
import Home from './home/Home'
import {Route, Routes} from 'react-router-dom' 
import Header from './component/Header'
import Footer from './component/Footer'
import {Helmet, HelmetProvider} from "react-helmet-async";
import Aboutus from './aboutus/Aboutus'
import Contactus from './contactus/Contactus'
import Kitchens from './kitchens/Kitchens'
import Cuisines from './cuisines/Cuisines'
export default function App() {
  return (
    <>

    
    <HelmetProvider>
    <Helmet>
      
  
  
    </Helmet>
    </HelmetProvider>


    <Header/>
     <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route  path='aboutus' element={<Aboutus/>} />
        <Route  path='contactus' element={<Contactus/>} />
        <Route  path='kitchens' element={<Kitchens/>} />
        <Route  path='cuisines' element={<Cuisines/>} />
      </Routes >
    <Footer/>
    
    </>
  )
}

