import React from 'react'
import { Zoom , Fade , Slide  , Rotate} from "react-awesome-reveal";

export default function Contactus() {
  return (
    <>
    
    <div id="smooth-wrapper">
  <div id="smooth-content">
 
    <div className="breadcumb-wrapper " id="breadcumbwrap">
      <div className="container">
        <div className="breadcumb-content">
          <h1 className="breadcumb-title">Contact</h1>
          <ul className="breadcumb-menu">
            <li>
              <a href="https://wordpress.themeholy.com/restar">Home</a>
            </li>
            <li>Contact</li>
          </ul>
        </div>
      </div>
    </div>

    <div className="restar-fluid">
      <div className="builder-page-wrapper">
        <div
          data-elementor-type="wp-page"
          data-elementor-id={69}
          className="elementor elementor-69"
        >
          <div
            className="elementor-element elementor-element-ad0dd73 e-con-full e-flex e-con e-parent"
            data-id="ad0dd73"
            data-element_type="container"
          >
            <div
              className="elementor-element elementor-element-2549723 e-flex e-con-boxed e-con e-child"
              data-id={2549723}
              data-element_type="container"
            >
              <div className="e-con-inner">
                <div
                  className="elementor-element elementor-element-f2f84cf e-con-full e-flex e-con e-child"
                  data-id="f2f84cf"
                  data-element_type="container"
                >
                  <div
                    className="elementor-element elementor-element-6abeaf2 e-con-full contact-feature-area e-flex e-con e-child"
                    data-id="6abeaf2"
                    data-element_type="container"
                    data-settings='{"background_background":"classic"}'
                  >
                    <div
                      className="elementor-element elementor-element-2e2720e elementor-widget elementor-widget-restarsectiontitle"
                      data-id="2e2720e"
                      data-element_type="widget"
                      data-widget_type="restarsectiontitle.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="title-area   mb-32">
                  <Fade direction='up' >     <h2 className="sec-title  ">Contact Information</h2></Fade>   
                          <div className="text-ani">
                  <Fade direction='up' >        <p className="sec-text">
                              Immerse yourself in a sophisticated and inviting
                              ambiance. The carefully curated decor sets the
                              stage for intimate dinners, celebrations.
                            </p></Fade>  
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-d704811 elementor-widget elementor-widget-restarcontactinfo"
                      data-id="d704811"
                      data-element_type="widget"
                      data-widget_type="restarcontactinfo.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="contact-feature-wrap">
                          <div className="contact-feature">
                            <div className="box-icon">
                              <i className="fal fa-location-dot" />
                            </div>
                            <div className="media-body">
                              <h3 className="box-title text-ani">Location</h3>
                              <p className="box-text text-ani">
                                Riverside 25, San Francisco, California
                              </p>
                            </div>
                          </div>
                          <div className="contact-feature">
                            <div className="box-icon">
                              <i className="fal fa-envelope" />
                            </div>
                            <div className="media-body">
                              <h3 className="box-title text-ani">
                                Email Address
                              </h3>
                              <p className="box-text text-ani">
                                <a href="mailto:info@restar.com">
                                  info@restar.com
                                </a>
                              </p>
                            </div>
                          </div>
                          <div className="contact-feature">
                            <div className="box-icon">
                              <i className="fal fa-phone" />
                            </div>
                            <div className="media-body">
                              <h3 className="box-title text-ani">
                                Phone Number
                              </h3>
                              <p className="box-text text-ani">
                                <a href="tel:+(163)-2654-3564">
                                  +(163)-2654-3564
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-83fb056 elementor-widget elementor-widget-restarsocial"
                      data-id="83fb056"
                      data-element_type="widget"
                      data-widget_type="restarsocial.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="th-social">
                          <a rel="nofollow" href="https://www.facebook.com/">
                            <i
                              aria-hidden="true"
                              className="fab fa-facebook-f"
                            />
                          </a>{" "}
                          <a rel="nofollow" href="https://www.twitter.com/">
                            <i aria-hidden="true" className="fab fa-twitter" />
                          </a>{" "}
                          <a rel="nofollow" href="https://www.instagram.com/">
                            <i
                              aria-hidden="true"
                              className="fab fa-instagram"
                            />
                          </a>{" "}
                          <a rel="nofollow" href="https://www.linkedin.com/">
                            <i
                              aria-hidden="true"
                              className="fab fa-linkedin-in"
                            />
                          </a>{" "}
                          <a rel="nofollow" href="https://pinterest.com/">
                            <i
                              aria-hidden="true"
                              className="fab fa-pinterest-p"
                            />
                          </a>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-95465a8 e-con-full e-flex e-con e-child"
                    data-id="95465a8"
                    data-element_type="container"
                  >
                    <div
                      className="elementor-element elementor-element-21e3716 elementor-widget elementor-widget-restarcontactform"
                      data-id="21e3716"
                      data-element_type="widget"
                      data-widget_type="restarcontactform.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="th-form-area">
                          <div
                            className="wpcf7 no-js"
                            id="wpcf7-f1531-p69-o1"
                            lang="en-US"
                            dir="ltr"
                          >
                            <div className="screen-reader-response">
                              <p
                                role="status"
                                aria-live="polite"
                                aria-atomic="true"
                              ></p>
                              <ul />
                            </div>
                            <form
                              action="https://wordpress.themeholy.com/restar/contact/#wpcf7-f1531-p69-o1"
                              method="post"
                              className="wpcf7-form init"
                              aria-label="Contact form"
                              noValidate="novalidate"
                              data-status="init"
                            >
                              <div style={{ display: "none" }}>
                                <input
                                  type="hidden"
                                  name="_wpcf7"
                                  defaultValue={1531}
                                />
                                <input
                                  type="hidden"
                                  name="_wpcf7_version"
                                  defaultValue="5.9.5"
                                />
                                <input
                                  type="hidden"
                                  name="_wpcf7_locale"
                                  defaultValue="en_US"
                                />
                                <input
                                  type="hidden"
                                  name="_wpcf7_unit_tag"
                                  defaultValue="wpcf7-f1531-p69-o1"
                                />
                                <input
                                  type="hidden"
                                  name="_wpcf7_container_post"
                                  defaultValue={69}
                                />
                                <input
                                  type="hidden"
                                  name="_wpcf7_posted_data_hash"
                                  defaultValue=""
                                />
                              </div>
                              <div className="contact-form">
                            <Fade direction='up' >   <h2 className="sec-title title-ani2">
                                  Get In Touch
                                </h2></Fade> 
                                <div className="row">
                                  <div className="form-group col-sm-6">
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="your-name"
                                    >
                                      <input
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                                        aria-required="true"
                                        aria-invalid="false"
                                        placeholder="Full Name"
                                        defaultValue=""
                                        type="text"
                                        name="your-name"
                                      />
                                    </span>
                                    <i className="fal fa-user" />
                                  </div>
                                  <div className="form-group col-sm-6">
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="your-email"
                                    >
                                      <input
                                        size={40}
                                        className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email form-control"
                                        aria-required="true"
                                        aria-invalid="false"
                                        placeholder="Email Address"
                                        defaultValue=""
                                        type="email"
                                        name="your-email"
                                      />
                                    </span>
                                    <i className="fal fa-envelope" />
                                  </div>
                                  <div className="form-group col-12">
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="phone-number"
                                    >
                                      <input
                                        size={40}
                                        className="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel form-control"
                                        aria-required="true"
                                        aria-invalid="false"
                                        placeholder="Phone Number"
                                        defaultValue=""
                                        type="tel"
                                        name="phone-number"
                                      />
                                    </span>
                                    <i className="fal fa-phone" />
                                  </div>
                                  <div className="form-group col-12">
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="subject"
                                    >
                                      <select
                                        className="wpcf7-form-control wpcf7-select form-select"
                                        aria-invalid="false"
                                        name="subject"
                                      >
                                        <option value="Select Subject">
                                          Select Subject
                                        </option>
                                        <option value="General Query">
                                          General Query
                                        </option>
                                        <option value="Help Support">
                                          Help Support
                                        </option>
                                        <option value="Sales Support">
                                          Sales Support
                                        </option>
                                      </select>
                                    </span>
                                    <i className="fal fa-chevron-down" />
                                  </div>
                                  <div className="form-group col-12">
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="message"
                                    >
                                      <textarea
                                        cols={40}
                                        rows={10}
                                        className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required form-control"
                                        aria-required="true"
                                        aria-invalid="false"
                                        placeholder="Type Appointment Note...."
                                        name="message"
                                        defaultValue={""}
                                      />
                                    </span>
                                    <i className="fal fa-pencil" />
                                  </div>
                                </div>
                                <button className="th-btn w-100 wpcf7">
                                  Send Message Now
                                </button>
                              </div>
                              <div
                                className="wpcf7-response-output"
                                aria-hidden="true"
                              ></div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-32beb65 e-flex e-con-boxed e-con e-parent"
            data-id="32beb65"
            data-element_type="container"
          >
            <div className="e-con-inner">
              <div
                className="elementor-element elementor-element-5f628f5 e-con-full e-flex e-con e-child"
                data-id="5f628f5"
                data-element_type="container"
              >
                <div
                  className="elementor-element elementor-element-f6f0573 elementor-widget elementor-widget-restarsectiontitle"
                  data-id="f6f0573"
                  data-element_type="widget"
                  data-widget_type="restarsectiontitle.default" 
                >
                  <div className="elementor-widget-container">
                    <div className="title-area text-center ">
                 <Fade direction='up' >    <h2 className="sec-title  ">
                        Come and visit our Branches
                      </h2></Fade> 
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-c74e0f4 elementor-widget elementor-widget-restarcontactinfo"
                  data-id="c74e0f4"
                  data-element_type="widget"
                  data-widget_type="restarcontactinfo.default"
                >
                  <div className="elementor-widget-container">
                    <div className="row gy-30">
                      <div className="col-lg-6">
                        <div className="location-card">
                          <div className="box-img">
                            <img
                              decoding="async"
                              src=" vendor/uploads/2024/05/location_1.jpg"
                              alt="location_1"
                            />
                          </div>
                          <div className="box-content">
                            <h3 className="box-title title-ani2">
                              Los Angeles, CA
                            </h3>
                            <p className="box-text title-ani2">
                              Riverside 25, San Francisco California
                            </p>
                            <p className="box-info title-ani2">
                              <i className="fal fa-envelope" />
                              contact@restaurantate.com
                            </p>
                            <p className="box-info title-ani2">
                              <i className="fal fa-phone" />
                              (487) 870 - 1087
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="location-card">
                          <div className="box-img">
                            <img
                              decoding="async"
                              src=" vendor/uploads/2024/05/location_2.jpg"
                              alt="location_2"
                            />
                          </div>
                          <div className="box-content">
                            <h3 className="box-title title-ani2">
                              San Francisco, CA
                            </h3>
                            <p className="box-text title-ani2">
                              Riverside 25, San Francisco California
                            </p>
                            <p className="box-info title-ani2">
                              <i className="fal fa-envelope" />
                              contact@restaurantate.com
                            </p>
                            <p className="box-info title-ani2">
                              <i className="fal fa-phone" />
                              (487) 870 - 1087
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}
