import React from 'react'



export default function Cuisines() {
  return (
    <>
      <div id="smooth-wrapper">
  <div id="smooth-content">
     <div className="breadcumb-wrapper custom-woo-class">
      <div className="container z-index-common">
        <div className="breadcumb-content">
          <h1 className="breadcumb-title">Shop</h1>
          <ul className="breadcumb-menu">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Shop</li>
          </ul>
        </div>
      </div>
    </div>
   
    <section className="th-product-wrapper product-details space-top space-extra-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="woocommerce-notices-wrapper" />
            {/* Tab Content */}
            <div className="tab-content" id="nav-tabContent">
              {/* Grid */}
              <div
                className="tab-pane fade show active"
                id="tab-grid"
                role="tabpanel"
                aria-labelledby="tab-shop-grid"
              >
                <div className="shop-grid-area">
                  <div className="row gy-40">
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <div className="product-wrapper product type-product post-172 status-publish first instock product_cat-breakfast product_tag-breakfast product_tag-food has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box">
                          <div className="product-img  transparent-img">
                            <a data-toggle="modal" data-target="#myModal">
                              <img
                                fetchpriority="high"
                                width={312}
                                height={312}
                                src="vendor/uploads/2024/05/menu_card_7.jpg"
                                className="attachment-restar-shop-small-image size-restar-shop-small-image wp-post-image"
                                alt=""
                                decoding="async"
                                sizes="(max-width: 312px) 100vw, 312px"
                              />
                            </a>
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-172"
                                data-id={172}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index7b34.html?add-to-cart=172"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={172}
                                data-product_sku="Breakfast-Platter"
                                aria-label="Add to cart: “Breakfast Platter”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_loop_item"
                                data-tinvwl_product_id={172}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={172}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="woocommerce-product-rating2">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <h3 className="product-title">
                              <a data-toggle="modal" data-target="#myModal">
                                Breakfast Platter
                              </a>
                            </h3>
                            <span className="product-price price">
                              <span className="woocommerce-Price-amount amount">
                                <span className="woocommerce-Price-currencySymbol">
                                  ₹
                                </span>
                                20
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <div className="product-wrapper product type-product post-654 status-publish instock product_cat-dessert product_cat-snack product_tag-food product_tag-snack has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box">
                          <div className="product-img  transparent-img">
                            <a data-toggle="modal" data-target="#myModal">
                              <img
                                width={300}
                                height={241}
                                src=" vendor/uploads/2024/05/menu_card_7.jpg"
                                className="attachment-restar-shop-small-image size-restar-shop-small-image wp-post-image"
                                alt=""
                                decoding="async"
                              />
                            </a>
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-654"
                                data-id={654}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index5d11.html?add-to-cart=654"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={654}
                                data-product_sku="Bruno-Scribble"
                                aria-label="Add to cart: “Bruno Scribble”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_loop_item"
                                data-tinvwl_product_id={654}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={654}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="woocommerce-product-rating2">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <h3 className="product-title">
                              <a data-toggle="modal" data-target="#myModal">
                                Bruno Scribble
                              </a>
                            </h3>
                            <span className="product-price price">
                              <span className="woocommerce-Price-amount amount">
                                <span className="woocommerce-Price-currencySymbol">
                                  ₹
                                </span>
                                24
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <div className="product-wrapper product type-product post-170 status-publish instock product_cat-breakfast product_tag-breakfast product_tag-food has-post-thumbnail sale shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box">
                          <div className="product-img  transparent-img">
                            <a data-toggle="modal" data-target="#myModal">
                              <img
                                width={312}
                                height={312}
                                src=" vendor/uploads/2024/04/product_1_11-312x312.png"
                                className="attachment-restar-shop-small-image size-restar-shop-small-image wp-post-image"
                                alt=""
                                decoding="async"
                                sizes="(max-width: 312px) 100vw, 312px"
                              />
                            </a>
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-170"
                                data-id={170}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index2139.html?add-to-cart=170"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={170}
                                data-product_sku="Butter-Pasta"
                                aria-label="Add to cart: “Butter Pasta”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_loop_item"
                                data-tinvwl_product_id={170}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={170}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                            <span className="product-tag">Sale</span>
                          </div>
                          <div className="product-content">
                            <div className="woocommerce-product-rating2">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <h3 className="product-title">
                              <a data-toggle="modal" data-target="#myModal">
                                Butter Pasta
                              </a>
                            </h3>
                            <span className="product-price price">
                              <ins>
                                <span className="woocommerce-Price-amount amount">
                                  <span className="woocommerce-Price-currencySymbol">
                                    ₹
                                  </span>
                                  18
                                </span>
                              </ins>
                              <del>
                                <span className="woocommerce-Price-amount amount">
                                  <span className="woocommerce-Price-currencySymbol">
                                    ₹
                                  </span>
                                  20
                                </span>
                              </del>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <div className="product-wrapper product type-product post-640 status-publish last instock product_cat-supper product_tag-dinner product_tag-food has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box">
                          <div className="product-img  transparent-img">
                            <a data-toggle="modal" data-target="#myModal">
                              <img
                                loading="lazy"
                                width={300}
                                height={241}
                                src=" vendor/uploads/2024/05/menu_card_1.jpg"
                                className="attachment-restar-shop-small-image size-restar-shop-small-image wp-post-image"
                                alt=""
                                decoding="async"
                              />
                            </a>
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-640"
                                data-id={640}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index1353.html?add-to-cart=640"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={640}
                                data-product_sku="Chicha-Morada"
                                aria-label="Add to cart: “Chicha Morada”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_loop_item"
                                data-tinvwl_product_id={640}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={640}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="woocommerce-product-rating2">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <h3 className="product-title">
                              <a data-toggle="modal" data-target="#myModal">
                                Chicha Morada
                              </a>
                            </h3>
                            <span className="product-price price">
                              <span className="woocommerce-Price-amount amount">
                                <span className="woocommerce-Price-currencySymbol">
                                  ₹
                                </span>
                                29
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <div className="product-wrapper product type-product post-165 status-publish first instock product_cat-lunch product_tag-food product_tag-lunch has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box">
                          <div className="product-img  transparent-img">
                            <a data-toggle="modal" data-target="#myModal">
                              <img
                                loading="lazy"
                                width={312}
                                height={312}
                                src=" vendor/uploads/2024/04/product_1_6-312x312.png"
                                className="attachment-restar-shop-small-image size-restar-shop-small-image wp-post-image"
                                alt=""
                                decoding="async"
                                sizes="(max-width: 312px) 100vw, 312px"
                              />
                            </a>
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-165"
                                data-id={165}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="indexc37d.html?add-to-cart=165"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={165}
                                data-product_sku="Chicken-Fried-Rice"
                                aria-label="Add to cart: “Chicken Fried Rice”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_loop_item"
                                data-tinvwl_product_id={165}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={165}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="woocommerce-product-rating2">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <h3 className="product-title">
                              <a data-toggle="modal" data-target="#myModal">
                                Chicken Fried Rice
                              </a>
                            </h3>
                            <span className="product-price price">
                              <span className="woocommerce-Price-amount amount">
                                <span className="woocommerce-Price-currencySymbol">
                                  ₹
                                </span>
                                29
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <div className="product-wrapper product type-product post-168 status-publish instock product_cat-dinner product_tag-dinner product_tag-food has-post-thumbnail sale shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box">
                          <div className="product-img  transparent-img">
                            <a data-toggle="modal" data-target="#myModal">
                              <img
                                loading="lazy"
                                width={312}
                                height={312}
                                src=" /vendor/uploads/2024/04/product_1_9-312x312.png"
                                className="attachment-restar-shop-small-image size-restar-shop-small-image wp-post-image"
                                alt=""
                                decoding="async"
                                sizes="(max-width: 312px) 100vw, 312px"
                              />
                            </a>
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-168"
                                data-id={168}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index0a67.html?add-to-cart=168"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={168}
                                data-product_sku="Chicken-Leg-Piece"
                                aria-label="Add to cart: “Chicken Leg Piece”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_loop_item"
                                data-tinvwl_product_id={168}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={168}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                            <span className="product-tag">Sale</span>
                          </div>
                          <div className="product-content">
                            <div className="woocommerce-product-rating2">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <h3 className="product-title">
                              <a data-toggle="modal" data-target="#myModal">
                                Chicken Leg Piece
                              </a>
                            </h3>
                            <span className="product-price price">
                              <ins>
                                <span className="woocommerce-Price-amount amount">
                                  <span className="woocommerce-Price-currencySymbol">
                                    ₹
                                  </span>
                                  25
                                </span>
                              </ins>
                              <del>
                                <span className="woocommerce-Price-amount amount">
                                  <span className="woocommerce-Price-currencySymbol">
                                    ₹
                                  </span>
                                  30
                                </span>
                              </del>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <div className="product-wrapper product type-product post-167 status-publish instock product_cat-breakfast product_cat-dinner product_tag-dinner product_tag-food has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box">
                          <div className="product-img  transparent-img">
                            <a data-toggle="modal" data-target="#myModal">
                              <img
                                loading="lazy"
                                width={312}
                                height={312}
                                src=" vendor/uploads/2024/04/product_1_8-312x312.png"
                                className="attachment-restar-shop-small-image size-restar-shop-small-image wp-post-image"
                                alt=""
                                decoding="async"
                                sizes="(max-width: 312px) 100vw, 312px"
                              />
                            </a>
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-167"
                                data-id={167}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index8c8a.html?add-to-cart=167"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={167}
                                data-product_sku="Chicken-with-Rice"
                                aria-label="Add to cart: “Chicken with Rice”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_loop_item"
                                data-tinvwl_product_id={167}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={167}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="woocommerce-product-rating2">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <h3 className="product-title">
                              <a data-toggle="modal" data-target="#myModal">
                                Chicken with Rice
                              </a>
                            </h3>
                            <span className="product-price price">
                              <span className="woocommerce-Price-amount amount">
                                <span className="woocommerce-Price-currencySymbol">
                                  ₹
                                </span>
                                30
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <div className="product-wrapper product type-product post-651 status-publish last instock product_cat-supper product_tag-food product_tag-snack has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box">
                          <div className="product-img  transparent-img">
                            <a data-toggle="modal" data-target="#myModal">
                              <img
                                loading="lazy"
                                width={300}
                                height={241}
                                src=" vendor/uploads/2024/05/menu_card_4.jpg"
                                className="attachment-restar-shop-small-image size-restar-shop-small-image wp-post-image"
                                alt=""
                                decoding="async"
                              />
                            </a>
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-651"
                                data-id={651}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index6373.html?add-to-cart=651"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={651}
                                data-product_sku="Crispy-Chicken"
                                aria-label="Add to cart: “Crispy Chicken”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_loop_item"
                                data-tinvwl_product_id={651}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={651}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="woocommerce-product-rating2">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <h3 className="product-title">
                              <a data-toggle="modal" data-target="#myModal">
                                Crispy Chicken
                              </a>
                            </h3>
                            <span className="product-price price">
                              <span className="woocommerce-Price-amount amount">
                                <span className="woocommerce-Price-currencySymbol">
                                  ₹
                                </span>
                                20
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <div className="product-wrapper product type-product post-161 status-publish first instock product_cat-lunch product_tag-food product_tag-lunch has-post-thumbnail sale shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box">
                          <div className="product-img  transparent-img">
                            <a data-toggle="modal" data-target="#myModal">
                              <img
                                loading="lazy"
                                width={312}
                                height={312}
                                src=" vendor/uploads/2024/04/product_1_1-312x312.png"
                                className="attachment-restar-shop-small-image size-restar-shop-small-image wp-post-image"
                                alt=""
                                decoding="async"
                                sizes="(max-width: 312px) 100vw, 312px"
                              />
                            </a>
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-161"
                                data-id={161}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index1038.html?add-to-cart=161"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={161}
                                data-product_sku="Egg-and-Cocumber"
                                aria-label="Add to cart: “Egg and Cocumber”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_loop_item"
                                data-tinvwl_product_id={161}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={161}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                            <span className="product-tag">Sale</span>
                          </div>
                          <div className="product-content">
                            <div className="woocommerce-product-rating2">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <h3 className="product-title">
                              <a data-toggle="modal" data-target="#myModal">
                                Egg and Cocumber
                              </a>
                            </h3>
                            <span className="product-price price">
                              <ins>
                                <span className="woocommerce-Price-amount amount">
                                  <span className="woocommerce-Price-currencySymbol">
                                    ₹
                                  </span>
                                  28
                                </span>
                              </ins>
                              <del>
                                <span className="woocommerce-Price-amount amount">
                                  <span className="woocommerce-Price-currencySymbol">
                                    ₹
                                  </span>
                                  32
                                </span>
                              </del>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <div className="product-wrapper product type-product post-169 status-publish instock product_cat-breakfast product_tag-breakfast product_tag-food has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box">
                          <div className="product-img  transparent-img">
                            <a data-toggle="modal" data-target="#myModal">
                              <img
                                loading="lazy"
                                width={312}
                                height={312}
                                src=" vendor/uploads/2024/04/product_1_10-312x312.png"
                                className="attachment-restar-shop-small-image size-restar-shop-small-image wp-post-image"
                                alt=""
                                decoding="async"
                                sizes="(max-width: 312px) 100vw, 312px"
                              />
                            </a>
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-169"
                                data-id={169}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index939e.html?add-to-cart=169"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={169}
                                data-product_sku="Grilled-Chicken"
                                aria-label="Add to cart: “Grilled Chicken”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_loop_item"
                                data-tinvwl_product_id={169}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={169}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="woocommerce-product-rating2">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <h3 className="product-title">
                              <a data-toggle="modal" data-target="#myModal">
                                Grilled Chicken
                              </a>
                            </h3>
                            <span className="product-price price">
                              <span className="woocommerce-Price-amount amount">
                                <span className="woocommerce-Price-currencySymbol">
                                  ₹
                                </span>
                                20
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <div className="product-wrapper product type-product post-175 status-publish instock product_cat-dinner product_tag-dinner product_tag-food has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box">
                          <div className="product-img  transparent-img">
                            <a data-toggle="modal" data-target="#myModal">
                              <img
                                loading="lazy"
                                width={312}
                                height={312}
                                src=" vendor/uploads/2024/04/product_1_14-312x312.png"
                                className="attachment-restar-shop-small-image size-restar-shop-small-image wp-post-image"
                                alt=""
                                decoding="async"
                                sizes="(max-width: 312px) 100vw, 312px"
                              />
                            </a>
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-175"
                                data-id={175}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index3e47.html?add-to-cart=175"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={175}
                                data-product_sku="Kozo-Sushi-Platter"
                                aria-label="Add to cart: “Kozo Sushi Platter”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_loop_item"
                                data-tinvwl_product_id={175}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={175}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="woocommerce-product-rating2">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <h3 className="product-title">
                              <a data-toggle="modal" data-target="#myModal">
                                Kozo Sushi Platter
                              </a>
                            </h3>
                            <span className="product-price price">
                              <span className="woocommerce-Price-amount amount">
                                <span className="woocommerce-Price-currencySymbol">
                                  ₹
                                </span>
                                20
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <div className="product-wrapper product type-product post-649 status-publish last instock product_cat-snack product_tag-food product_tag-snack has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box">
                          <div className="product-img  transparent-img">
                            <a data-toggle="modal" data-target="#myModal">
                              <img
                                loading="lazy"
                                width={300}
                                height={241}
                                src=" vendor/uploads/2024/05/menu_card_2.jpg"
                                className="attachment-restar-shop-small-image size-restar-shop-small-image wp-post-image"
                                alt=""
                                decoding="async"
                              />
                            </a>
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-649"
                                data-id={649}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="indexb381.html?add-to-cart=649"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={649}
                                data-product_sku="Oysters-Dozen"
                                aria-label="Add to cart: “Oysters Dozen”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_loop_item"
                                data-tinvwl_product_id={649}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={649}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="woocommerce-product-rating2">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <h3 className="product-title">
                              <a data-toggle="modal" data-target="#myModal">
                                Oysters Dozen
                              </a>
                            </h3>
                            <span className="product-price price">
                              <span className="woocommerce-Price-amount amount">
                                <span className="woocommerce-Price-currencySymbol">
                                  ₹
                                </span>
                                50
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Grid */}
              {/* List */}
              <div
                className="tab-pane fade"
                id="tab-list"
                role="tabpanel"
                aria-labelledby="tab-shop-list"
              >
                <div className="shop-list-area">
                  <div className="row gy-40">
                    <div className="col-md-6">
                      <div className="product-wrapper product type-product post-172 status-publish first instock product_cat-breakfast product_tag-breakfast product_tag-food has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box list-view">
                          <div className="product-img">
                            <a data-toggle="modal" data-target="#myModal">
                              <img
                                loading="lazy"
                                width={454}
                                height={454}
                                src=" vendor/uploads/2024/04/product_1_13.png"
                                className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                                alt=""
                                decoding="async"
                                sizes="(max-width: 454px) 100vw, 454px"
                              />
                            </a>
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-172"
                                data-id={172}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index7b34.html?add-to-cart=172"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={172}
                                data-product_sku="Breakfast-Platter"
                                aria-label="Add to cart: “Breakfast Platter”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_horizontal_loop_item"
                                data-tinvwl_product_id={172}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={172}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="rating-wrap">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <a
                              href=" product-category/breakfast/index.html"
                              className="product-category"
                            >
                              Breakfast
                            </a>
                            <h3 className="product-title">
                              <a data-toggle="modal" data-target="#myModal">
                                Breakfast Platter
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-wrapper product type-product post-654 status-publish instock product_cat-dessert product_cat-snack product_tag-food product_tag-snack has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box list-view">
                          <div className="product-img">
                            <a data-toggle="modal" data-target="#myModal">
                              <img
                                loading="lazy"
                                width={300}
                                height={241}
                                src=" vendor/uploads/2024/05/menu_card_7.jpg"
                                className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                                alt=""
                                decoding="async"
                              />
                            </a>
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-654"
                                data-id={654}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index5d11.html?add-to-cart=654"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={654}
                                data-product_sku="Bruno-Scribble"
                                aria-label="Add to cart: “Bruno Scribble”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_horizontal_loop_item"
                                data-tinvwl_product_id={654}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={654}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="rating-wrap">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <a
                              href=" product-category/dessert/index.html"
                              className="product-category"
                            >
                              Dessert
                            </a>
                            <h3 className="product-title">
                              <a data-toggle="modal" data-target="#myModal">
                                Bruno Scribble
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-wrapper product type-product post-170 status-publish instock product_cat-breakfast product_tag-breakfast product_tag-food has-post-thumbnail sale shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box list-view">
                          <div className="product-img">
                            <img
                              loading="lazy"
                              width={454}
                              height={454}
                              src=" /vendor/uploads/2024/04/product_1_11.png"
                              className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                              alt=""
                              decoding="async"
                              sizes="(max-width: 454px) 100vw, 454px"
                            />
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-170"
                                data-id={170}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index2139.html?add-to-cart=170"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={170}
                                data-product_sku="Butter-Pasta"
                                aria-label="Add to cart: “Butter Pasta”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_horizontal_loop_item"
                                data-tinvwl_product_id={170}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={170}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                            <span className="product-tag">Sale</span>
                          </div>
                          <div className="product-content">
                            <div className="rating-wrap">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <a
                              href=" product-category/breakfast/index.html"
                              className="product-category"
                            >
                              Breakfast
                            </a>
                            <h3 className="product-title">
                              <a href=" product/butter-pasta/index.html">
                                Butter Pasta
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-wrapper product type-product post-640 status-publish last instock product_cat-supper product_tag-dinner product_tag-food has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box list-view">
                          <div className="product-img">
                            <img
                              loading="lazy"
                              width={300}
                              height={241}
                              src=" vendor/uploads/2024/05/menu_card_1.jpg"
                              className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                              alt=""
                              decoding="async"
                            />
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-640"
                                data-id={640}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index1353.html?add-to-cart=640"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={640}
                                data-product_sku="Chicha-Morada"
                                aria-label="Add to cart: “Chicha Morada”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_horizontal_loop_item"
                                data-tinvwl_product_id={640}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={640}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="rating-wrap">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <a
                              href=" product-category/supper/index.html"
                              className="product-category"
                            >
                              Supper
                            </a>
                            <h3 className="product-title">
                              <a href=" product/chicha-morada/index.html">
                                Chicha Morada
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-wrapper product type-product post-165 status-publish first instock product_cat-lunch product_tag-food product_tag-lunch has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box list-view">
                          <div className="product-img">
                            <img
                              loading="lazy"
                              width={454}
                              height={454}
                              src=" vendor/uploads/2024/04/product_1_6.png"
                              className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                              alt=""
                              decoding="async"
                              sizes="(max-width: 454px) 100vw, 454px"
                            />
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-165"
                                data-id={165}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="indexc37d.html?add-to-cart=165"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={165}
                                data-product_sku="Chicken-Fried-Rice"
                                aria-label="Add to cart: “Chicken Fried Rice”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_horizontal_loop_item"
                                data-tinvwl_product_id={165}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={165}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="rating-wrap">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <a
                              href=" product-category/lunch/index.html"
                              className="product-category"
                            >
                              Lunch
                            </a>
                            <h3 className="product-title">
                              <a href=" product/chicken-fried-rice/index.html">
                                Chicken Fried Rice
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-wrapper product type-product post-168 status-publish instock product_cat-dinner product_tag-dinner product_tag-food has-post-thumbnail sale shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box list-view">
                          <div className="product-img">
                            <img
                              loading="lazy"
                              width={454}
                              height={454}
                              src=" vendor/uploads/2024/04/product_1_9.png"
                              className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                              alt=""
                              decoding="async"
                              sizes="(max-width: 454px) 100vw, 454px"
                            />
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-168"
                                data-id={168}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index0a67.html?add-to-cart=168"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={168}
                                data-product_sku="Chicken-Leg-Piece"
                                aria-label="Add to cart: “Chicken Leg Piece”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_horizontal_loop_item"
                                data-tinvwl_product_id={168}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={168}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                            <span className="product-tag">Sale</span>
                          </div>
                          <div className="product-content">
                            <div className="rating-wrap">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <a
                              href=" product-category/dinner/index.html"
                              className="product-category"
                            >
                              Dinner
                            </a>
                            <h3 className="product-title">
                              <a href=" product/chicken-leg-piece/index.html">
                                Chicken Leg Piece
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-wrapper product type-product post-167 status-publish instock product_cat-breakfast product_cat-dinner product_tag-dinner product_tag-food has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box list-view">
                          <div className="product-img">
                            <img
                              loading="lazy"
                              width={454}
                              height={454}
                              src=" vendor/uploads/2024/04/product_1_8.png"
                              className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                              alt=""
                              decoding="async"
                              sizes="(max-width: 454px) 100vw, 454px"
                            />
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-167"
                                data-id={167}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index8c8a.html?add-to-cart=167"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={167}
                                data-product_sku="Chicken-with-Rice"
                                aria-label="Add to cart: “Chicken with Rice”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_horizontal_loop_item"
                                data-tinvwl_product_id={167}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={167}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="rating-wrap">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <a
                              href=" product-category/breakfast/index.html"
                              className="product-category"
                            >
                              Breakfast
                            </a>
                            <h3 className="product-title">
                              <a href=" product/chicken-with-rice/index.html">
                                Chicken with Rice
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-wrapper product type-product post-651 status-publish last instock product_cat-supper product_tag-food product_tag-snack has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box list-view">
                          <div className="product-img">
                            <img
                              loading="lazy"
                              width={300}
                              height={241}
                              src=" vendor/uploads/2024/05/menu_card_4.jpg"
                              className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                              alt=""
                              decoding="async"
                            />
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-651"
                                data-id={651}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index6373.html?add-to-cart=651"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={651}
                                data-product_sku="Crispy-Chicken"
                                aria-label="Add to cart: “Crispy Chicken”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_horizontal_loop_item"
                                data-tinvwl_product_id={651}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={651}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="rating-wrap">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <a
                              href=" product-category/supper/index.html"
                              className="product-category"
                            >
                              Supper
                            </a>
                            <h3 className="product-title">
                              <a href=" product/crispy-chicken/index.html">
                                Crispy Chicken
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-wrapper product type-product post-161 status-publish first instock product_cat-lunch product_tag-food product_tag-lunch has-post-thumbnail sale shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box list-view">
                          <div className="product-img">
                            <img
                              loading="lazy"
                              width={454}
                              height={454}
                              src=" vendor/uploads/2024/04/product_1_1.png"
                              className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                              alt=""
                              decoding="async"
                              sizes="(max-width: 454px) 100vw, 454px"
                            />
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-161"
                                data-id={161}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index1038.html?add-to-cart=161"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={161}
                                data-product_sku="Egg-and-Cocumber"
                                aria-label="Add to cart: “Egg and Cocumber”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_horizontal_loop_item"
                                data-tinvwl_product_id={161}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={161}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                            <span className="product-tag">Sale</span>
                          </div>
                          <div className="product-content">
                            <div className="rating-wrap">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <a
                              href=" product-category/lunch/index.html"
                              className="product-category"
                            >
                              Lunch
                            </a>
                            <h3 className="product-title">
                              <a href=" product/egg-and-cocumber/index.html">
                                Egg and Cocumber
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-wrapper product type-product post-169 status-publish instock product_cat-breakfast product_tag-breakfast product_tag-food has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box list-view">
                          <div className="product-img">
                            <img
                              loading="lazy"
                              width={454}
                              height={454}
                              src=" vendor/uploads/2024/04/product_1_10.png"
                              className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                              alt=""
                              decoding="async"
                              sizes="(max-width: 454px) 100vw, 454px"
                            />
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-169"
                                data-id={169}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index939e.html?add-to-cart=169"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={169}
                                data-product_sku="Grilled-Chicken"
                                aria-label="Add to cart: “Grilled Chicken”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_horizontal_loop_item"
                                data-tinvwl_product_id={169}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={169}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="rating-wrap">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <a
                              href=" product-category/breakfast/index.html"
                              className="product-category"
                            >
                              Breakfast
                            </a>
                            <h3 className="product-title">
                              <a href=" product/grilled-chicken/index.html">
                                Grilled Chicken
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-wrapper product type-product post-175 status-publish instock product_cat-dinner product_tag-dinner product_tag-food has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box list-view">
                          <div className="product-img">
                            <img
                              loading="lazy"
                              width={454}
                              height={454}
                              src=" vendor/uploads/2024/04/product_1_14.png"
                              className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                              alt=""
                              decoding="async"
                              sizes="(max-width: 454px) 100vw, 454px"
                            />
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-175"
                                data-id={175}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="index3e47.html?add-to-cart=175"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={175}
                                data-product_sku="Kozo-Sushi-Platter"
                                aria-label="Add to cart: “Kozo Sushi Platter”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_horizontal_loop_item"
                                data-tinvwl_product_id={175}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={175}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="rating-wrap">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <a
                              href=" product-category/dinner/index.html"
                              className="product-category"
                            >
                              Dinner
                            </a>
                            <h3 className="product-title">
                              <a href=" product/kozo-sushi-platter/index.html">
                                Kozo Sushi Platter
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-wrapper product type-product post-649 status-publish last instock product_cat-snack product_tag-food product_tag-snack has-post-thumbnail shipping-taxable purchasable product-type-simple">
                        <div className="th-product th-product-box list-view">
                          <div className="product-img">
                            <img
                              loading="lazy"
                              width={300}
                              height={241}
                              src=" vendor/uploads/2024/05/menu_card_2.jpg"
                              className="attachment-restar-shop-product-list size-restar-shop-product-list wp-post-image"
                              alt=""
                              decoding="async"
                            />
                            <div className="actions">
                              <a
                                href="#"
                                className="icon-btn woosq-btn woosq-btn-649"
                                data-id={649}
                                data-effect="mfp-3d-unfold"
                              >
                                <i className="far fa-eye" />
                              </a>
                              <a
                                href="indexb381.html?add-to-cart=649"
                                data-quantity={1}
                                className="cart-button icon-btn product_type_simple add_to_cart_button ajax_add_to_cart"
                                data-product_id={649}
                                data-product_sku="Oysters-Dozen"
                                aria-label="Add to cart: “Oysters Dozen”"
                                rel="nofollow"
                              >
                                <i className="far fa-cart-plus" />
                              </a>
                              <div
                                className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_horizontal_loop_item"
                                data-tinvwl_product_id={649}
                              >
                                <a
                                  role="button"
                                  tabIndex={0}
                                  name=""
                                  aria-label=""
                                  className="tinvwl_add_to_wishlist_button tinvwl-icon-heart no-txt icon-btn tinvwl-position-shortcode"
                                  data-tinv-wl-list="[]"
                                  data-tinv-wl-product={649}
                                  data-tinv-wl-productvariation={0}
                                  data-tinv-wl-productvariations="[]"
                                  data-tinv-wl-producttype="simple"
                                  data-tinv-wl-action="add"
                                />
                                <div className="tinvwl-tooltip" />
                              </div>
                            </div>
                          </div>
                          <div className="product-content">
                            <div className="rating-wrap">
                              <div
                                className="star-rating"
                                role="img"
                                aria-label="Rated 5.00 out of 5"
                              >
                                <span style={{ width: "100%" }}>
                                  Rated
                                  <strong className="rating">5.00</strong>
                                  out of 5
                                </span>
                              </div>
                            </div>
                            <a
                              href=" product-category/snack/index.html"
                              className="product-category"
                            >
                              Snack
                            </a>
                            <h3 className="product-title">
                              <a href=" product/oysters-dozen/index.html">
                                Oysters Dozen
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End List */}
            </div>
            {/* End Tab Content */}
            <div className="th-pagination text-center pt-50">
              <ul>
                <li>
                  <a className="active">1</a>
                </li>
                <li>
                  <a href="page/2/index.html">2</a>
                </li>
                <li>
                  <a href="page/2/index.html">
                    <i className="far fa-arrow-right" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    
 
  </div>
</div>
    </>
  )
}
